fieldset {
	padding: 0;
	margin: 0;
	border: 0;
}

label {
	margin-top: 0;
}

select {
	background-color: white;
}

#{$forms} {
	margin-bottom: 0;
}
.error {
	#{$forms} {
		margin-bottom: .5rem;
	}
	.help-inline, .required {
		color: red;
	}
}
.input {
	margin-bottom: $margin-bottom;
}

form .flex-small, form .flex-large {
	margin-bottom: 0;
}

.form-navigation ul {
	list-style: none;
	padding: 0;
	margin-bottom: $margin-bottom;
}
