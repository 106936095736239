.slide {
    position: relative;
    padding: 0;
    z-index: 2;
    padding-bottom: 40px;
    overflow: hidden;

    &__arrows {
        position: absolute;
        width: 98%;
        bottom: 0px;
        // align-items: center;
        // display: flex;
    }

    &__arrow {
        margin-bottom: 0;
    }

    &__bullets {
        flex-grow: 1;
        text-align: center;
    }

    &__bullet {
        margin: 0 .25rem;
        position: relative;

        &::before {
            background: $color-foreground;
            border-radius: 100%;
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) scale(0);
            transition: transform 0.2s ease-in-out;
            height: 8px;
            width: 8px;
        }

        &--active::before {
            transform: translate(-50%, -50%) scale(1);
        }
    }

    // HERO VERSION
    &--controls {
        .slide__main {
            overflow: hidden;
        }

        .slide__track {
            margin-bottom: 0;
        }

        .slide__arrows:hover {
            background: $color-border;
        }

        .slide__arrow {
            background: transparent;
            border: 1px solid #b4b3b3 !important;
            border-radius: 50%;
            bottom: 0;
            position: absolute;
            transform: translateY(-40%);
            transition: all 0.2s ease-in-out;
            z-index: 85;

            &:hover {
                background: white;
                // border: 1px solid $color-font-light-grey !important;
            }

            &--left,
            &--right {
                background-position: center;
                background-repeat: no-repeat;
                padding: 20px;
                background-size: 20px;
                transition: background 0.3s, color 0.3s;
            }

            &--left {
                left: 70px;
                background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 26.3.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Livello_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 302 302' style='enable-background:new 0 0 302 302;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:none;stroke:%23000000;stroke-width:50;stroke-miterlimit:10;%7D%0A%3C/style%3E%3Cpolyline class='st0' points='215.9,21.1 86.1,151 215.9,280.9 '/%3E%3C/svg%3E%0A");

                &:hover {
                    // background: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 26.3.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Livello_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 302 302' style='enable-background:new 0 0 302 302;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:none;stroke:%23000000;stroke-width:50;stroke-miterlimit:10;%7D%0A%3C/style%3E%3Cpolyline class='st0' points='215.9,21.1 86.1,151 215.9,280.9 '/%3E%3C/svg%3E%0A"),white;
                    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 26.3.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Livello_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 302 302' style='enable-background:new 0 0 302 302;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:none;stroke:%23fff;stroke-width:50;stroke-miterlimit:10;%7D%0A%3C/style%3E%3Cpolyline class='st0' points='215.9,21.1 86.1,151 215.9,280.9 '/%3E%3C/svg%3E%0A");
                    background-color: $darker-bg;
                    border-color: $darker-bg;
                    background-size: 20px;
                    background-position: center;
                    background-repeat: no-repeat;
                }
            }

            &--right {
                left: 180px;
                background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 26.3.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Livello_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 302 302' style='enable-background:new 0 0 302 302;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:none;stroke:%23000000;stroke-width:50;stroke-miterlimit:10;%7D%0A%3C/style%3E%3Cpolyline class='st0' points='86.1,280.9 215.9,151 86.1,21.1 '/%3E%3C/svg%3E%0A");

                &:hover {
                    // background: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 26.3.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Livello_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 302 302' style='enable-background:new 0 0 302 302;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:none;stroke:%23000000;stroke-width:50;stroke-miterlimit:10;%7D%0A%3C/style%3E%3Cpolyline class='st0' points='86.1,280.9 215.9,151 86.1,21.1 '/%3E%3C/svg%3E%0A"),white;
                    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 26.3.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Livello_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 302 302' style='enable-background:new 0 0 302 302;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:none;stroke:%23ffffff;stroke-width:50;stroke-miterlimit:10;%7D%0A%3C/style%3E%3Cpolyline class='st0' points='86.1,280.9 215.9,151 86.1,21.1 '/%3E%3C/svg%3E");
                    background-color: $darker-bg;
                    border-color: $darker-bg;
                    background-size: 20px;
                    background-position: center;
                    background-repeat: no-repeat;
                }
            }


            &--numerator {
                position: absolute;
                left: -webkit-calc(0% + 3rem);
                left: -moz-calc(0% + 3rem);
                left: calc(0% + 3rem);

                // bottom: 0;
                bottom: 25px;
            }

            &--left {
                left: -webkit-calc(50% - 47px);
                left: -moz-calc(50% - 47px);
                left: calc(50% - 47px);

                // bottom: calc(0% - 25px);
                // bottom: -webkit-calc(50% - 25px);
                // bottom: -moz-calc(50% - 25px);
            }

            &--right {
                left: -webkit-calc(50% + 5px);
                left: -moz-calc(50% + 5px);
                left: calc(50% + 5px);

                // bottom: calc(0% - 25px);
                // bottom: -webkit-calc(0% - 25px);
                // bottom: -moz-calc(0% - 25px);
            }

            i {

                &::after,
                &::before {
                    color: $color-font;
                }
            }
        }

        .slide__bullets {
            position: absolute;
            bottom: -45px;
            left: 50%;
            transform: translateX(-50%);
            margin: 1rem 0;
        }

        .slide__bullet {
            background: #ff6868;

            &:hover {
                background: $color-font-light-grey;
            }

            &::before {
                background: currentColor;
            }
        }
    }

    &.hide-numeration {
        .slide__arrow--numerator {
            display: none;
        }
    }
}

@media screen and (min-width: $medium) {
    .slide {
        .slide__track {
            padding-bottom: 2rem;
        }
    }
}

@media screen and (max-width: $small) {
    .slide {
        padding-bottom: 60px;
    }

    .slide--controls {
        .slide__arrow {

            // &--numerator{
            //     left: -webkit-calc(50% - 14px);
            //     left: -moz-calc(50% - 14px);
            //     left: calc(50% - 14px);     
            // }
            &--left {
                left: unset;
                right: -webkit-calc(0% + 102px);
                right: -moz-calc(0% + 102px);
                right: calc(0% + 102px);
            }

            &--right {
                left: unset;
                right: -webkit-calc(0% + 45px);
                right: -moz-calc(0% + 45px);
                right: calc(0% + 45px);
            }
        }
    }

    .glide__slide {
        >section {
            width: 100%;
            margin-left: 0;
            padding-left: 0 !important;
            padding-right: 2.5rem !important;
        }
    }
}

// Aggiungere bordo grigio al testo + Togliere Box shadow per gli slider a mobile
@media screen and (max-width: $small) {
    .glide__slide {
        margin-bottom: 20px;

        .two-columns {
            flex-direction: column-reverse;

            .column-one,
            .column-two {
                width: 100%;
            }

            .column-one>.neos-contentcollection {
                padding-top: 1rem;
                border: 1px solid $color-font-grey;
                border-top: 0;
                padding-left: 1rem;
                padding-right: 1rem;
            }

            .column-two {
                margin-bottom: 0;
            }
        }

        .jonnitto-prettyembed-wrapper {
            &.shadow {

                &--top,
                &--bottom,
                &--left,
                &--right {

                    a,
                    .jonnitto-prettyembed {
                        &::before {
                            content: unset;
                            display: none;
                        }
                    }
                }
            }
        }

        .shadow--bottom,
        .shadow--right,
        .shadow--top,
        .shadow--left {

            .adam-image__image::before,
            .plyr::before {
                display: none;
            }
        }
    }
}