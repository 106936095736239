.adam-image,
.jonnitto-plyr-container {
    .plyr {
        overflow: visible;

        &.plyr--hide-controls {
            transform: unset;

            .plyr__controls {
                transform: unset;
            }
        }

        .plyr__controls {
            transform: unset;
            transition: opacity .4s ease-in-out;
        }
    }

    &.shadow {

        &--top,
        &--bottom,
        &--left,
        &--right {

            .adam-image__image::before,
            .plyr::before {
                content: " ";
                background-color: $color-primary;
                display: block;
                position: absolute;
                height: 100%;
                width: 100%;
                z-index: 0;
            }
        }

        &--top {

            .adam-image__image::before,
            .plyr::before {
                top: -30px;
            }
        }

        &--bottom {

            .adam-image__image::before,
            .plyr::before {
                bottom: -30px;
            }
        }

        &--left {

            .adam-image__image::before,
            .plyr::before {
                left: -30px;
            }
        }

        &--right {

            .adam-image__image::before,
            .plyr::before {
                right: -30px;
            }
        }
    }

    &__image,
    &__wrapper,
    .plyr {
        position: relative;
        z-index: 1;
    }

    &__image,
    .plyr {
        display: inline-block;

        &>img {
            position: relative;
            height: 100%;
            width: auto;
            z-index: 1;
        }
    }

    &>.adam__text-wrapper {
        z-index: 1;
    }
}

// ###################

.two-columns-column {
    .jonnitto-plyr-container {
        margin-bottom: 0;

        .plyr {
            display: flex;
        }
    }
}

.jonnitto-prettyembed-wrapper {
    .jonnitto-prettyembed {
        &::after {
            bottom: 0;
            content: " ";
            background-color: $color-background;
            display: block;
            position: absolute;
            height: 99%;
            width: 99%;
            z-index: -1;
            top: 2px;
            left: 2px;
        }
    }

    &.shadow {

        &--top,
        &--bottom,
        &--left,
        &--right {

            a,
            .jonnitto-prettyembed {
                &::before {
                    content: " ";
                    background-color: $color-primary;
                    display: block;
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    z-index: -2;
                }

                &::after {
                    content: " ";
                    background-color: $color-background;
                    display: block;
                    position: absolute;
                    height: 99%;
                    width: 99%;
                    z-index: -1;
                    top: 2px;
                    left: 2px;
                }
            }
        }

        &--top {

            a,
            .jonnitto-prettyembed {
                &::before {
                    top: -30px;
                }
            }
        }

        &--bottom {

            a,
            .jonnitto-prettyembed {
                &::before {
                    bottom: -30px;
                }
            }
        }

        &--left {

            a,
            .jonnitto-prettyembed {
                &::before {
                    left: -30px;
                }
            }
        }

        &--right {

            a,
            .jonnitto-prettyembed {
                &::before {
                    right: -30px;
                }
            }
        }
    }

    a,
    .jonnitto-prettyembed {
        overflow: visible !important;
        z-index: 100;
    }
}


@media screen and (max-width: $small) {
    .jonnitto-prettyembed-wrapper {
        &.shadow {

            &--top {

                a,
                .jonnitto-prettyembed {
                    &::before {
                        top: -15px;
                    }
                }
            }

            &--bottom {

                a,
                .jonnitto-prettyembed {
                    &::before {
                        bottom: -15px;
                    }
                }
            }

            &--left {

                a,
                .jonnitto-prettyembed {
                    &::before {
                        left: -15px;
                    }
                }
            }

            &--right {

                a,
                .jonnitto-prettyembed {
                    &::before {
                        right: -15px;
                    }
                }
            }
        }
    }

    .adam-image,
    .jonnitto-plyr-container {
        &.shadow {
            &--top {

                .adam-image__image::before,
                .plyr::before {
                    top: -15px;
                }
            }

            &--bottom {

                .adam-image__image::before,
                .plyr::before {
                    bottom: -15px;
                }
            }

            &--left {

                .adam-image__image::before,
                .plyr::before {
                    left: -15px;
                }
            }

            &--right {

                .adam-image__image::before,
                .plyr::before {
                    right: -15px;
                }
            }
        }

    }
}

.jonnitto-prettyembed--youtube  {
    padding-top: 56.3% !important;
}

