.splide {
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        margin-bottom: 30px;

        &__title {
            margin: 0;
        }
    }

    &__pagination {
        display: none;
    }

    &__arrows {
        align-items: flex-end;
        display: flex;
        justify-content: space-between;
        min-width: 105px;
    }

    &__arrow {
        &, &:active, &:focus {
            background-color: $color-foreground;
            background-position: center;
            background-repeat: no-repeat;
            background-size: 20px;
            border: 1px solid #b4b3b3;
            border-radius: 50%;
            margin: 0;
            padding: 20px;
            transition: all 0.2s ease-in-out;
            z-index: 50;
        }

        &.splide__arrow--prev,
        &.splide__arrow--next {
            &:hover {
                border-color: $color-background;
                background-color: $color-background;
                background-position: center;
                background-repeat: no-repeat;
                background-size: 20px;
            }
        }

        &.splide__arrow--prev {
            &, &:active, &:focus {
                background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 26.3.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Livello_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 302 302' style='enable-background:new 0 0 302 302;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:none;stroke:%23000000;stroke-width:50;stroke-miterlimit:10;%7D%0A%3C/style%3E%3Cpolyline class='st0' points='215.9,21.1 86.1,151 215.9,280.9 '/%3E%3C/svg%3E%0A");
            }

            &:hover {
                background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 26.3.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Livello_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 302 302' style='enable-background:new 0 0 302 302;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:none;stroke:%23fff;stroke-width:50;stroke-miterlimit:10;%7D%0A%3C/style%3E%3Cpolyline class='st0' points='215.9,21.1 86.1,151 215.9,280.9 '/%3E%3C/svg%3E%0A");
            }
        }

        &.splide__arrow--next {
            &, &:active, &:focus {
                background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 26.3.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Livello_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 302 302' style='enable-background:new 0 0 302 302;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:none;stroke:%23000000;stroke-width:50;stroke-miterlimit:10;%7D%0A%3C/style%3E%3Cpolyline class='st0' points='86.1,280.9 215.9,151 86.1,21.1 '/%3E%3C/svg%3E%0A");
            }

            &:hover {
                background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 26.3.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Livello_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 302 302' style='enable-background:new 0 0 302 302;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:none;stroke:%23ffffff;stroke-width:50;stroke-miterlimit:10;%7D%0A%3C/style%3E%3Cpolyline class='st0' points='86.1,280.9 215.9,151 86.1,21.1 '/%3E%3C/svg%3E");
            }
        }
    }

    &__track {
        height: auto;
        padding: 0;
        width: 100%;
    }

    &__list {
        list-style: none;
        margin: 0;
        padding: 0;
        height: 100%;
    }

    &__slide {
        float: left;
        height: 100%;
        margin: 0 30px 0 0;
        max-width: 900px;

        &__image {
            height: 400px;
            margin-bottom: 10px;
        }
    }

    @media screen and (max-width: $medium) {
        &__slide__image {
            height: 320px;
        }
    }

    @media screen and (max-width: $small) {
        &__slide__image {
            height: 250px;
        }
    }
}