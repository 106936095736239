.adam__share {
    a:hover {
        cursor: pointer;
    }

    &>&__button {
        background-color: $color-primary;
        border-color: transparent;
        border-radius: 100px;
        color: $color-foreground;
        margin: 0;
        padding: .75rem;
    }

    &__title {
        font-size: 1.25rem;
        font-weight: 700;
        margin-bottom: 15px;
    }

    &__list {
        display: flex;
        list-style-type: none;
        padding: 0;
        justify-content: space-between;
        margin-bottom: 30px;
        width: 100%;
    }

    &__social {
        border: 1px solid #dedede;
        border-radius: 500px;
        display: block;
        line-height: 1rem;
        padding: 16px;
        height: 55px;
        width: 55px;

        &>img {
            width: 100%;
        }
    }

    &__link {
        &__title {
            font-size: 1rem;
            font-weight: 700;
            margin-bottom: 15px;
        }

        &__url {
            position: relative;

            &>&__input {
                color: $color-font-grey;
                padding: 10px;
                font-size: .8rem;
                font-weight: 700;
            }

            &>&__copy {
                background-color: $color-foreground;
                border-color: transparent;
                color: $color-dark;
                font-size: .815rem;
                font-weight: 700;
                margin: 1px;
                position: absolute;
                height: calc(100% - 2px);
                right: 0;
                top: 0;
            }
        }
    }

    .adam__modal.modal--share {
        .adam__modal__dialog {
            max-width: 530px;
            width: 100%;
        }
    }
}


@media screen and (max-width: $medium) {
    .adam__share {
        &__list {
            flex-wrap: wrap;

            li {
                flex: unset;
                justify-content: center;
                display: flex;
                margin-bottom: 10px;
            }
        }
    }
}

@media screen and (max-width: $small) {
    .adam__share {
        &__list {
            li {
                flex: 0 0 33%;
            }
        }
    }
}
