.adam__accordion {
    $root: &;

    &__item {
        border: 1px solid;

        &:not(:first-of-type) {
            border-top: 0;
        }

        &:first-of-type {
            &, #{$root}__item__title {
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
            }
        }

        &:last-of-type {
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;

            &.item--hidden {
                #{$root}__item__title {
                    border-bottom-left-radius: 5px;
                    border-bottom-right-radius: 5px;
                }
            }
        }

        &.item--block {
            #{$root}__item {
                &__title::after {
                    transform: rotate(-180deg);
                    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 25.3.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Livello_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 26 26' style='enable-background:new 0 0 26 26;' width='27' height='27' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Crect x='6.3' y='11.7' class='st0' width='13.5' height='2.7'/%3E%3C/svg%3E%0A");
                }
            }
        }

        &__title {
            align-items: center;
            border-radius: 0;
            display: flex;
            font-size: 1rem;
            margin: 0;
            padding: 1rem 1.25rem;
            position: relative;
            text-align: left;
            width: 100%;
            
            &::after {
                background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 25.3.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Livello_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 26 26' style='enable-background:new 0 0 26 26;' width='27' height='27' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Crect x='11.7' y='6.3' class='st0' width='2.7' height='13.5'/%3E%3Crect x='6.3' y='11.7' class='st0' width='13.5' height='2.7'/%3E%3C/svg%3E%0A");
                background-repeat: no-repeat;
                background-position: 100% 100% !important;
                content: "";
                flex-shrink: 0;
                margin-left: auto;
                height: 1.25rem;
                width: 1.25rem;
                transition: transform .2s ease-in-out;
            }
        }

        &__content {
            font-size: 14px;
            padding: .5rem 2rem 1.5rem 2rem;
        }
    }
}