.button {
	margin: 0.5rem 0.5rem 0.5rem 0;
	transition: all 0.3s;
}
.button:last-child {
	margin-right: 0;
}

.icon-button,
a.icon-button {
	font-size: $button-font-size + .5rem;
	padding: 0.5rem;
	border: 0 !important;
}
