.adam__partners {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 30px;
}

.adam__partner {
    padding: 8px;
}

.adam__partner,
.adam__partner__logo {
    height: 100%;
    width: 100%;
}

@media screen and (max-width: $large) {
    .adam__partners {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (max-width: $medium) {
    .adam__partners {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: $x-small) {
    .adam__partners {
        grid-template-columns: repeat(1, 1fr);

        .adam__partner {
            margin: auto;
            max-width: 150px;
        }
    }
}