.flowpack-searchplugin-search {
    form {
        display: flex;
        position: relative;
        align-items: center;
        margin-bottom: 10px;

        input[type="search"] {
            padding-right: 130px;
            border-radius: 30px;
            padding-left: 50px;
            position: relative;

            &:active,
            &:focus {
                box-shadow: unset;
                border-color: #c5c4c4;
            }
        }

        &::after {
            position: absolute;
            display: block;
            content: ' ';
            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 26.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Livello_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 20 20' style='enable-background:new 0 0 20 20;' width='20' height='20' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23767676;%7D%0A%3C/style%3E%3Cpath class='st0' d='M18.9,17c-0.8,0.7-1.1,1.1-1.9,1.8c-1-1.2-2-2.3-2.9-3.4c-0.8-1-1.5-1.1-2.7-0.5c-3.2,1.6-6.9,0.6-9.1-2.3 c-2-2.6-1.9-6.5,0.2-9.1c2.2-2.7,5.9-3.5,9.1-2c3.6,1.7,5.2,6,3.4,9.8c-0.5,1.1-0.5,1.8,0.5,2.6C16.5,14.8,17.6,15.8,18.9,17z M8.3,2.8c-3.1,0-5.5,2.5-5.5,5.5c0,3,2.4,5.3,5.4,5.4s5.4-2.3,5.5-5.3C13.7,5.3,11.3,2.8,8.3,2.8z'/%3E%3C/svg%3E%0A");
            background-size: 20px 20px;
            height: 20px;
            width: 20px;
            left: 18px;
            z-index: 2;
        }

        button {
            position: absolute;
            right: 0;
            border-radius: 30px;
            margin: 0;
            height: 100%;
            width: 120px;
        }
    }

    ol {
        margin-top: 2rem;
        padding: 0;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 30px;
        grid-row-gap: 30px;
        color: $color-font;

        @media screen and (max-width: $medium) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media screen and (max-width: $small) {
            grid-template-columns: repeat(1, 1fr);
        }

        li {
            background-color: $color-light;
            flex: 0 0 30%;
            padding: 30px;
            list-style-type: none;
            margin-bottom: 40px;
            position: relative;

            a:first-child {
                font-size: 1.5em;
                color: $color-font;
                font-weight: 700;
                padding-bottom: 15px;
            }

            p {
                margin-top: 15px;
                margin-bottom: 30px;
            }

            .breadcrumb {
                font-size: 0.8rem;
                position: absolute;
                bottom: 20px;

                span {
                    display: none;
                }

                a {
                    font-weight: 400;
                    color: $primary;
                }
            }

            em {
                font-weight: 700;
                font-style: normal;
            }

            .breadcrumb {
                color: $primary;
                font-weight: 700;

                a {
                    &:last-child {
                        font-weight: bold;
                    }
                }
            }
        }
    }

    // OLD DESIGN
    // ol {
    //     padding: 0;
    //     li {
    //         padding: 30px 0 0 0;
    //         list-style-type: none;
    //         a:first-child {
    //             font-size: 1.5em;
    //             font-weight: 700;
    //         }
    //         .breadcrumb {
    //             font-size: 0.8rem;
    //             span {
    //                 display: none;
    //             }
    //             a {
    //                 font-weight: 400;
    //                 color: $grey-font;
    //             }
    //         }
    //         .breadcrumb, em {
    //             font-weight: 700;
    //         }
    //     }
    // }
}


@media screen and (max-width: $large) {
    .flowpack-searchplugin-search {
        ol {
            li {
                flex: 0 0 45%;
            }
        }
    }
}


@media screen and (max-width: $small) {
    .flowpack-searchplugin-search {
        ol {
            li {
                flex: 0 0 100%;
            }
        }
    }
}

@media screen and (max-width: $x-small) {
    .flowpack-searchplugin-search {
        form {
            display: block;
            position: relative;
            align-items: center;

            input[type="search"] {
                padding-right: 12px;
            }

            button {
                position: relative;
                right: 0;
                margin: 20px 0;
                height: 100%;
                width: 120px;
            }

            &::after {
                top: 15px;
            }
        }
    }
}

.column-image,
#{$has-background} {
    .flowpack-searchplugin-search {
        color: white;
        // ol{
        //     color: white;
        //     .breadcrumb{
        //         color: $primary;
        //         a{
        //             color: white;
        //         }
        //     }
        // }
        // OLD DESGIN
        // ol{
        //     color: white;
        //     .breadcrumb{
        //         color: $primary;
        //         a{
        //             color: white;
        //         }
        //     }
        // }
    }
}
