.adam__modal {
    $root: &;

    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    display: none;
    outline: 0;
    z-index: 1072;

    overflow: hidden;
    -webkit-overflow-scrolling: touch;

    &::before {
        content: ' ';
        background-color: $color-dark;
        display: block;
        position: absolute;
        opacity: .5;
        height: 100%;
        width: 100%;
    }

    &__show {
        overflow: hidden;

        .adam__navbar {
            z-index: 80;
        }
    }

    &__dialog {
        background-color: $color-foreground;
        left: 50%;
        top: 50%;
        padding: 40px;
        position: absolute;
        transform: translate(-50%, -50%);
        overflow-y: auto;
        max-height: 90%;
        width: 100%;
        z-index: 1073;

        #{$root}__close {
            background-color: $color-background-light;
            border-color: transparent;
            border-radius: 500px;
            color: $color-dark;
            font-size: 1.5rem;
            padding: 2px 7px;
            position: absolute;
            top: 15px;
            right: 15px;
        }

        &.modal--button {
            max-width: 600px;
        }
    }

    &__title {
        font-size: 1.2rem;
        font-weight: 700;
        margin-bottom: 30px;
    }

    &__button {
        background-color: $color-primary;
        border-color: transparent;
        border-radius: 100px;
        color: $color-foreground;
        margin: 0;
        padding: .75rem;

        &__image:hover {
            cursor: pointer;
        }
    }

    &__icon {
        border-bottom: 15px solid $color-primary;
        border-right: 15px solid $color-primary;
        display: block;
        margin: 0 auto 40px;
        transform: rotate(45deg);
        height: 100px;
        width: 50px;
    }

    &.modal--open {
        display: block;
    }

    &.modal--form &__dialog {
        max-width: 500px;
    }
}
