.adam__tabs {
    &__pills {
        display: flex;
        flex-direction: row;
        margin-bottom: 1rem;
        flex-wrap: wrap;
    }

    &__pill {
        border: 1px solid transparent;
        border-radius: 40px;
        color: $color-primary;
        cursor: pointer;
        display: inline-block;
        font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica, Arial, sans-serif;
        font-size: 1rem;
        font-weight: 600;
        padding: .5rem 1.25rem;
        line-height: 1;
        margin: .5rem .5rem .5rem 0;
        transition: all .3s;
        text-align: center;
        text-decoration: none;
        text-transform: none;
        vertical-align: middle;
        -webkit-appearance: none;

        &.item--active {
            background: $color-primary;
            border: 1px solid $color-primary;
            color: $color-foreground;
        }
    }

}

@media screen and (max-width: $small) {
    .adam__tabs {
        &__pills {
            display: block;
        }

        &__pill {
            display: block;
            width: fit-content;
            margin: auto;
            margin-bottom: 20px;
        }
    }
}
