.adam__navbar {
    $root: &;
    position: relative;

    .icon-search {
        margin-right: 20px;

        &:hover {
            text-decoration: none !important;
        }

        i {
            color: $font-color;

            &:hover {
                text-decoration: none !important;
            }
        }
    }

    .icon-search-mobile {
        display: block;
        text-align: left;
        margin-bottom: 3rem;

        .search-text {
            margin-left: 10px;
            font-weight: 700;
            font-size: 1.3rem;
        }
    }

    .icon-menu {
        color: $color-font;
    }

    .primary__menu__items {
        display: flex;
        align-items: center;
        justify-content: center;

        ul.menu-items {
            display: flex;
            align-items: center;
            justify-content: center;
            list-style: none;
            height: 100%;
            margin: 0;
            margin-bottom: 0;
            padding: 0;
        }
    }

    &--inverted {
        color: $color-foreground;
        position: absolute;
        top: 45px;
        left: 0;
        width: 100%;
        z-index: 150;

        .icon-menu {
            color: $color-foreground;
        }

        .icon-search {
            i {
                color: $color-foreground;
            }
        }

        #{$root}__brand {
            .brand {
                filter: brightness(0) invert(1);
            }
        }

        #{$root}__menu {
            &__item {
                height: 100%;

                &>button,
                &>a {
                    color: $color-foreground;
                }
            }
        }

        #{$root}__language {
            &__search {
                color: $color-foreground;
            }

            &__change {
                background-color: $color-foreground;
                border-color: $color-foreground;
                color: $color-background;
            }
        }
    }

    &--open {
        #{$root}__menu {
            &__item {

                &>button,
                &>a {
                    color: inherit;
                }
            }
        }

        #{$root}__language {
            &__search {
                color: inherit;
            }

            &__change {
                background-color: $color-primary;
                border-color: $color-primary;
                color: $color-foreground;
            }
        }

        #{$root}__primary__menu {
            visibility: visible;
            opacity: 100%;
            bottom: 0 !important;
        }
    }

    &>.container {
        display: grid;
        gap: 0 0;
        grid-template-columns: auto 2fr 180px;
        grid-template-rows: 1fr;
        grid-template-areas: ". . .";

        @media screen and (max-width: $medium) {
            display: flex;
            justify-content: space-between;
        }
    }

    &__brand {
        align-self: center;
        padding: 0;
        width: 230px;

        .brand {
            display: block;
            padding: 0;
            width: 100%;

            &>img {
                width: 100%;

                @media screen and (min-width: 300px) and (max-width: $large) {
                    max-width: 200px;
                }
            }
        }
    }

    &__menu {
        display: flex;
        align-items: center;
        justify-content: center;
        list-style: none;
        margin: 0;
        padding: 0;

        &__item {
            padding: 0 15px;
            height: 100%;
            display: flex;
            align-items: stretch;

            &:hover,
            &:active,
            &:focus {
                &+.adam__navbar__menu__sub {
                    display: block;
                }
            }

            &>button,
            &>a {
                color: $color-background;
                font-weight: 700;
            }

            &>a {
                align-self: center;

                &:hover,
                &:active,
                &:focus {
                    color: $color-primary;
                    text-decoration: none;
                }
            }

            &>button {
                background: none;
                border: none;
                padding: 0;
                margin: 0;

                &:hover,
                &:active,
                &:focus {
                    background: none;
                    border: none;
                    color: $color-primary;
                }
            }
        }

        &__sub {
            display: none;
            background-color: $color-foreground;
            padding: 50px 0;
            position: absolute;
            left: 0;
            top: 100%;
            width: 100%;
            z-index: 150;

            &:hover {
                display: block;
            }

            &.menu-sub--products {
                ul {
                    display: block;
                }

                &>.container {
                    display: flex;
                    align-items: stretch;
                    justify-content: space-between;
                }
            }

            .adam__sub {
                list-style-type: none;
                margin: 0;
                padding: 0;

                &>li {
                    margin-bottom: 10px;

                    a {
                        color: $color-default;
                        font-size: 26px;
                        font-weight: 700;

                        &:hover,
                        &:focus,
                        &:active {
                            color: $color-primary;
                            text-decoration: none;
                        }
                    }
                }

                &.sub--links {
                    &>li a {
                        font-size: 16px;
                        text-decoration: underline;

                        &::after {
                            content: '';
                            border: solid $color-background;
                            border-width: 0 2px 2px 0;
                            display: inline-block;
                            margin-left: 15px;
                            padding: 3px;
                            transform: rotate(-45deg);
                        }
                    }
                }

                &.sub--company {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    width: 100%;
                }

                &.sub--contacts {
                    display: flex;
                    width: 100%;

                    .adam__sub {
                        &__contact {
                            width: 40%;

                            &>p {
                                font-size: 30px;
                                font-weight: 700;
                                margin: 30px 0;
                            }
                        }

                        &__info-box {
                            background-color: $color-light;
                            display: flex;
                            justify-content: stretch;
                            position: relative;
                            width: 60%;

                            &__text {
                                flex: 0 0 50%;
                                padding: 40px 60px;

                                &>p {
                                    line-height: 22px;

                                    &>a {
                                        color: $color-default;
                                    }
                                }
                            }

                            &__image {
                                flex: 0 0 50%;
                                width: 100%;
                                vertical-align: top;
                            }
                        }
                    }
                }
            }
        }
    }

    .menu-toggler-mobile {
        background: transparent;
        border: none;

    }

    &__language {
        padding: 25px 0;
        width: 230px;
        position: relative;
        text-align: right;
        white-space: nowrap;

        &__search,
        &__change {
            margin: 0;
        }

        &__search {
            background: none;
            border: none;
            color: $color-default;

            &:hover,
            &:focus,
            &:active {
                background: none;
                border: none;
                color: $color-default;
            }
        }

        &__change {
            border-radius: 25px;
            padding: 10px 15px;
        }

        &__menu {
            background-color: $color-foreground;
            display: block;
            list-style: none;
            padding: 15px 35px;
            right: -25%;
            // visibility: hidden;
            // opacity: 0%;
            // transition: opacity 0.5s ease-out;
            overflow-y: auto;
            z-index: 152;
            width: 25%;
            position: fixed;
            border-radius: 0;

            bottom: 0;
            height: 100%;
            max-height: unset;
            transition: right bottom 0.5s ease-in-out;

            // &:hover,
            // &:active,
            // &:focus {
            //     // visibility: visible;
            //     // opacity: 100%;
            // }

            .language-list {
                display: flex;
                height: auto;
                flex-direction: column;
                align-items: stretch;
                padding: 0;

                hr {
                    margin: 10px 0;
                    width: 100%;
                    height: 1px;
                    background-color: $color-font-grey;
                }

                li {
                    display: flex;
                    align-items: center;
                    flex: 0 1 40px;

                    a {
                        font-weight: 700 !important;
                        color: $color-background !important;
                    }
                }
            }

            &>li {
                margin: 8px 0;
                text-align: left;

                &>a {
                    color: $color-default;

                    &.active {
                        color: $color-primary;
                    }

                    &:hover,
                    &:active,
                    &:focus {
                        color: $color-primary;
                    }
                }
            }
        }
    }

    &__language__menu {
        width: 350px;
        position: fixed;
        border-radius: 0;
        bottom: 0;
        height: 100%;
        max-height: unset;
        transition: right 0.5s ease-in-out;

        @media screen and (max-width: $medium) {
            width: 100%;
            height: 90%;
            bottom: -110%;
            right: 0%;
            transition: bottom 0.5s ease-in-out;
        }


        .icon-close {
            width: 90%;
            background-color: $color-light;
            width: 40px;
            height: 40px;
            background-size: 40%;
            margin: auto;
            margin-right: 0;

            &:hover {
                cursor: pointer;
            }
        }

        &__search {
            background: none;
            border: none;
            color: $color-default;

            &:hover,
            &:focus,
            &:active {
                background: none;
                border: none;
                color: $color-default;
            }
        }

        &__change {
            border-radius: 25px;
            padding: 10px 15px;
        }
    }

    &--open-sidebar {
        .adam__navbar__language__menu__side {
            // visibility: visible !important;
            // opacity: 100% !important;
            bottom: 0;
            top: 0;
            right: 0%;
            height: 100%;

            a {
                font-weight: 700;
                color: $color-background;
            }

            @media screen and (max-width: $medium) {
                top: unset;
                height: 90%;
                box-shadow: 0 0 10px -3px $color-default;
            }
        }

        @media screen and (max-width: $small) {
            bottom: 0%;
        }

    }

    .primary__menu__mobile {
        background: $color-foreground;
        bottom: -100%;
        color: $color-background;
        display: flex;
        flex-direction: column;
        left: 0;
        opacity: 1;
        overflow: scroll;
        padding: 15px 35px;
        position: fixed;
        height: calc(100% - 45px);
        width: 100%;
        transition: bottom 0.5s ease-in-out;
        z-index: 149;

        .adam__navbar__language {
            padding: 12px 0 25px 0;

            hr {
                margin: 15px 0;
                width: 100%;
                height: 1px;
                background-color: $color-font-grey;
            }
        }

        .primary__menu__mobile__items {
            justify-content: flex-start;

            ul.link-list {
                display: flex;
                height: fit-content;
                flex-direction: column;
                align-items: stretch;
                padding: 0;
                width: 100%;

                hr {
                    margin: 15px 0;
                    width: 100%;
                    height: 1px;
                    background-color: $color-font-grey;
                }

                li {
                    display: flex;
                    align-items: center;
                    flex: 1;
                    margin: 7px 0 7px 0;
                    padding-left: 0;

                    a {
                        font-size: 1.5rem;
                        color: $color-font;
                        font-weight: 700 !important;
                    }

                    .text-grey {
                        color: $color-font-grey !important;
                    }
                }
            }
        }

        .adam__navbar__brand__close {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 2rem;

            img {
                width: 70%;
            }
        }

        .adam__navbar__language {
            padding: 12px 0 25px 0;
        }
    }
}


@media screen and (min-width: $small) and (max-width: $large) {
    .adam__navbar {
        &__language__menu {
            right: -350px;
            width: 350px;
        }
    }
}

@media screen and (max-width: $medium) {
    .adam__navbar {

        // Questa è per la modifica di far passare il menu a mobile con uno stack precedente
        .hide-mobile {
            display: none !important;
        }

        .hide-desktop {
            display: block !important;
        }

        .primary__menu__mobile {
            .adam__navbar__language {
                padding: 0 0 25px 0;
            }

            .primary__menu__mobile__items {
                ul.link-list {
                    li {
                        a.text-grey {
                            font-size: 18px !important;
                        }
                    }
                }
            }

            .search-text {
                font-size: 18px;
            }
        }
    }

    .adam__navbar__language {
        width: 100%;
    }
}

@media screen and (min-width: $medium) {
    .adam__navbar {
        &--open {
            background-color: $color-foreground;
            color: $color-background;

            .brand {
                filter: none !important;
            }
        }

        &--open-sidebar {
            .adam__navbar__language__menu__side {
                box-shadow: 0 0 10px -3px $color-default;
                height: 100%;
                top: unset;
            }
        }

        &>.container {
            display: flex;
            justify-content: space-between;
        }

        &__language__menu {
            bottom: -105%;
            right: -25%;
            transition: right 0.5s ease-in-out;
        }
    }
}