.adam__rs {
    display: grid;
    grid-column-gap: 30px;
    grid-template-columns: 200px 1fr;

    &__filters {
        display: none;
        margin-bottom: 30px;
    }

    &__sidebar__title {
        color: $color-font;
        font-size: 1rem;
        font-weight: 700;
        line-height: 1rem;
        margin-bottom: 20px;
    }

    &__filter {
        margin-bottom: 30px;

        &__title {
            font-weight: 700;
            margin-bottom: 15px;
        }

        &__items {
            list-style-type: none;
            padding-left: 10px;
        }

        .adam__checkbox {
            margin-bottom: 5px;

            &__box {
                border: 1px solid #e3e3e3;
                background-color: $color-light;
                height: 15px;
                width: 15px;

                &::after {
                    border-width: 0 2px 2px 0;
                    left: 5px;
                    top: 3px;
                    height: 7px;
                    width: 4px;
                }
            }

            input:checked ~ &__box {
                border: $color-dark;
            }

            &__text {
                color: rgba($color-dark, .7);
            }

            & > input:checked ~ span {
                color: $color-dark;
            }
        }
    }

    &__results {
        width: 100%;

        &__items {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            &::after {
                // Questo after rende l'elenco allineano correttamente anche con 2 elementi 
                content: "";
                flex: 0 0 32%;
            }
        }
    }
}

.adam__resource {
    flex: 0 0 32%;
    margin-bottom: 80px;
    min-width: 230px;

    &__image {
        display: flex;
        line-height: 1rem;
        position: relative;

        &>img {
            display: block;
            margin: auto;
            float: left;
            width: 100%;
            height: 450px;
            object-fit: cover;
        }
    }

    &__title {
        color: $color-font;
        font-size: 1.2rem;
        font-weight: 700;
        margin: 20px 0 10px 0;
    }

    &__description {
        margin-bottom: 5px;
    }

    &__category {
        background-color: $color-primary;
        color: $color-foreground;
        font-size: .875rem;
        font-weight: 600;
        line-height: 1.125rem;
        padding: 2px 10px;
        position: absolute;
        right: 10px;
        top: 10px;
        text-transform: lowercase;
    }

    &__pagination {
        background-color: $color-background-alt;
        display: block;
        padding: 15px 0;
        text-align: center;
        width: 100%;

        &__link {
            cursor: pointer;
            color: $color-font;
            font-weight: 700;
            margin: 0 10px;

            &.link--active {
                color: $color-primary;

                &:active,
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    &__discover {
        display: block;
        font-weight: 700;
        margin-top: 15px;
        text-decoration: underline;
    }
}

@media screen and (max-width: $large) {
    .adam__rs {
        &__results__items {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    .adam__resource {
        flex: 0 0 45%;
    }
}

@media screen and (max-width: $small) {
    .adam__rs {
        display: flex;
        flex-direction: column;

        &__filters {
            display: block;
        }

        &__sidebar {
            margin-bottom: 30px;

            &.sidebar--hide {
                display: none;
            }
        }
    }

    .adam__resource {
        flex: 0 0 100%;
        margin-bottom: 60px;
    }
}
