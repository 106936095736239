.adam__scrollprogress {
    &.background-{
        &alternate, &white{
            *{
                color: $font-color;
            }
            .adam__scrollprogress__navigation{
                &__item{
                    & > a {
                        &::before{
                            background-color: $primary ;
                        }
                        &::after{
                            background-color: $primary ;
                        }
                        &.item--active {
                            color: $primary;
                        }
                    }
                }
            }
        }
    }
}


.adam__scrollprogress {
    color: $color-foreground;
    position: relative;
    padding: 75px 75px 0 75px;

    &.background-{
        &primary{
            background-color: $primary;
        }
        &alternate{
            background-color: $alternate-background;
        }
        &dark{
            background-color: $color-background;
        }
        &white{
            background-color: white;
        }
    }

    &__navigation {
        position: sticky;
        left: 35px;
        top: 25%;

        float: left;
        margin: 0;
        padding: 40px 0;
        list-style: none;
        max-width: 170px;
        z-index: 3;

        &__item {
            position: relative;
            padding-bottom: 3rem;
            line-height: 0.1;
            font-size: 14px;
            & > a {
                color: $color-foreground;
                position: relative;
                display: block;
                min-height: 40px;
                &:hover, &:focus, &:active {
                    text-decoration: none;
                }

                &::before{
                    display: block;
                    content: "";
                    position: absolute;
                    background-color: white;
                    opacity: 0.5;
                    transition: all 0.2s;
                    width: 4px;
                    height: 90px;
                    left: -15px;
                    bottom: 30px;
                }

                &::after{
                    content: "";
                    position: absolute;
                    transition: all 0.2s;
                    background-color: white;
                    opacity: 1;
                    width: 10px;
                    border-radius: 20px;
                    height: 10px;
                    left: -18px;
                    top: 6px;
                    z-index: 12;
                }
                
                &.previous__navigation{
                    &::before{
                        opacity: 1;
                    }
                }
                &.item--active {
                    text-decoration: none;
                    color: $color-foreground;
                    &::before{
                        opacity: 1;
                    }
                    &::after{
                        transform: scale(1.4);
                        opacity: 1;
                    }
                    
                }

            }
            
            &:last-child{
                padding-bottom: 0rem;
            }
            &:first-child{
                & > a {
                    &::before{
                        display: none;
                    }
                }
            }
        }
    }

    &__items {
        position: relative;
        z-index: 2;
        width: 85%;
        margin: auto;
    }

    &__item {
        padding: 40px 0;
    }
}

@media screen and (max-width: $medium) {
    .adam__scrollprogress {
        padding: 75px 25px 0 25px;

        &__navigation {
            max-width: 100px;
            top: 10%;
            &__item{
                & > a{
                    min-height: 80px;
                    &::before{
                        height: 125px;
                        bottom: 72px;
                    }
                }
            }
        }

        &__items {
            width: 100%;
        }

    }

    .navigation--mobile{
        display: sticky;
    }
    
    .navigation--desktop{
        display: none;
    }
}

@media screen and (max-width: $small) {
    .adam__scrollprogress {

        &__item{
            padding: 20px 0;
        }

        &__navigation {
            display: none;
            top: 5%;
            &__item{
                & > a{
                    font-size: 0;
                }
            }
        }
        &__container{
            padding-right: 0 !important;
            padding-left: 0 !important;
        }
        .scrollprogress__item{
            .adam__lottie{
                max-width: 40%;
                margin: auto;    
            }
        }
    }
}



@media screen and (min-width: $medium) {
    .navigation--mobile{
        display: none;
    }
    
    .navigation--desktop{
        display: block;
    }
    
}

@media screen and (min-width: $medium) {
    .adam__scrollprogress {

        // background-color: blue !important;
        
        .adam__scrollprogress__container{
            max-width: 1200px;
        }

        &__navigation {
            &.navigation--desktop{
                // background-color: yellow !important;
            }
        }

        &__items {
            width: 100%;
            padding-left: 250px;
        }

    }

}