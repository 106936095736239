@include only-small-breakpoint {
    .hide-mobile {
        display: none !important;
    }
}

@include small-breakpoint {
    .hide-desktop {
        display: none !important;
    }
}

.neos-backend {
    .full-container.hide-desktop,
    .container.hide-desktop {
        display: block !important;
        opacity: 0.5;
    }
}

@each $value,
$multiplier in $spacing-scale {
    .padding-top-#{$value} {
        padding-top: $mobile-spacing-scale-unit * $multiplier;
    }

    .padding-bottom-#{$value} {
        padding-bottom: $mobile-spacing-scale-unit * $multiplier;
    }

    .margin-top-#{$value} {
        margin-top: $mobile-spacing-scale-unit * $multiplier;
    }

    .margin-bottom-#{$value} {
        margin-bottom: $mobile-spacing-scale-unit * $multiplier;
    }


    [class*="negative-margin"] {
        position: relative;
        z-index: 5;
    }

    .negative-margin {
        &-small {
            margin-top: -2rem;

            @media screen and (max-width: $medium) {
                margin-top: 0rem;
            }
        }

        &-medium {
            margin-top: -3rem;

            @media screen and (max-width: $medium) {
                margin-top: 0rem;
            }
        }

        &-big {
            margin-top: -4rem;

            @media screen and (max-width: $medium) {
                margin-top: 0rem;
            }
        }

        &-large {
            margin-top: -6rem;

            @media screen and (max-width: $medium) {
                margin-top: -2rem;
            }
        }
    }
}

@include small-breakpoint {
    @each $value, $multiplier in $spacing-scale {
        .padding-top-#{$value} {
            padding-top: $spacing-scale-unit * $multiplier;
        }

        .padding-bottom-#{$value} {
            padding-bottom: $spacing-scale-unit * $multiplier;
        }

        .margin-top-#{$value} {
            margin-top: $spacing-scale-unit * $multiplier;
        }

        .margin-bottom-#{$value} {
            margin-bottom: $spacing-scale-unit * $multiplier;
        }
    }
}

.align-items-center {
    align-items: center;
}

.align-items-bottom {
    align-items: flex-end;
}

.squared-columns {
    #{$has-background} {
        padding: 0;
    }

    .two-columns {
        margin: 0;

        .two-columns-column {
            padding: 0;

            .container:first-child {
                padding: 0 5rem;

                @media screen and (max-width: $large) {
                    padding: 0 2rem;
                }
            }

            @media screen and (max-width: $small) {
                margin-bottom: 0;
            }
        }
    }

    @media screen and (max-width: $small) {
        .two-columns {
            flex-direction: column;

            .column-one,
            .column-two {
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: $small) {
    .invert-tablet {
        flex-direction: column-reverse !important;

        .column-one,
        .column-two {
            width: 100%;
        }
    }

    .column-tablet {
        .two-columns {
            flex-direction: column !important;

            .column-one,
            .column-two {
                width: 100%;
            }
        }
    }

}

@media screen and (max-width: $small) {
    .invert {
        flex-direction: column-reverse !important;
        align-items: flex-start;

        .column-one,
        .column-two {
            width: 100%;
        }
    }

    .hide-tablet {
        display: none !important;
    }

    .show-tablet {
        display: block !important;
    }

    .text-center-mobile {
        text-align: center;
    }
}

img {
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}