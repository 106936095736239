.adam__scrollspy {
    &__content {
        list-style: none;
        margin: 0;
        padding: 0;
    
        li .item--active {
            color: $color-font !important;
            font-weight: 700 !important;
        }
    }
}
