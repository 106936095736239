$button-font-family: $font-family;

$primary: $color-primary;
$primary-color: $color-primary;

$color-secondary: $color-foreground;

$secondary: $color-secondary;
$secondary-color: $color-secondary;

$alternate-color: $color-default;

$alternate-background: $color-background-alt;
$secondary-dark-background: $color-background-dark;
$cream: $color-background-light;

$grey-font: $color-font-grey;
$light-grey-font: $color-font-light-grey;

$font-color: $color-default;
$heading-font-color: $color-default;

$darker-bg: #1b1b1b;

$background-colors: (
	"primary": $primary,
	"secondary": $secondary,
	"alternate": $alternate-background,
    "white": $color-foreground,
    "dark": $color-background,
    "alternative-dark": $color-background-dark,
    "cream": $color-background-light,
	"darker": $darker-bg,
);

$item-padding-scale: (
	"none": 0px,
	"s10": 10px,
	"s20": 20px,
	"s30": 30px,
	"s40": 40px,
	"s50": 50px,
) !default;

// Mobile heading font size
$h1-mobile: 1.75rem;
$h2-mobile: 1.5rem;
$h3-mobile: 1.25rem;
$h4-mobile: 1.1rem;
$h5-mobile: 1rem;

// Heading font size
$h1: 3rem;
$h2: 2.75rem;
$h3: 2.5rem;
$h4: 2.25rem;
$h5: 2rem;
$h6: 1.75rem;