.adam__history {
    background-color: $color-background;
    color: $color-foreground;
    position: relative;
    padding: 75px 75px 0 75px;

    &__navigation {
        position: sticky;
        left: 35px;
        top: 25%;

        float: left;
        margin: 0;
        padding: 0;
        list-style: none;
        max-width: 50px;
        z-index: 3;

        &__item {
            font-weight: 700;
            padding: 4px 0;

            & > a {
                color: $color-border;

                &:hover,
                &:focus,
                &:active {
                    color: $color-border;
                    text-decoration: none;
                }

                &.item--active {
                    color: $color-foreground;
                }
            }
        }
    }

    &__year {
        position: absolute;
        top: 0;
        left: 0;
        text-align: center;
        height: 100%;
        width: 100%;
        z-index: 0;

        & > span {
            color: $color-background;
            font-family: 'Salvagnini Display', sans-serif;
            font-size: 25.5vw;
            position: sticky;
            text-shadow: 0 0 2px $color-primary;
            top: 35%;
        }
    }

    &__items {
        position: relative;
        margin: auto;
        width: 85%;
        z-index: 2;
    }

    &__item {
        padding: 40px 0;

        &__title {
            font-size: 28px;
            font-weight: 700;
            width: 50%;
        }

        &__image {
            display: none;
            width: 100%;
            margin-bottom: 2rem;
        }

        &__description {
            color: $color-primary;
            font-weight: 700;
        }
    }

    // Media queries
    @media screen and (max-width: $medium) {
        padding: 75px 25px 0 25px;
    
        &__navigation {
            left: 0;
            display: none;
        }
    
        &__items {
            width: 100%;
        }
    
        &__item {
            &__title {
                width: 100%;
            }
        }
    }

    @media screen and (max-width: $small) {
        &__year > span {
            top: 20%;
        }

        &__item {
            &__image.image--mobile {
                display: block;
            }

            & > .adam-column img {
                display: none;
            }
        }
    }
}