a.adam__button,
button.adam__button {
    margin: 0;

    &>img {
        vertical-align: middle;
    }

    &.button--fade {
        border-radius: 36px;
        display: block;
        padding: 0;
        overflow: hidden;
        text-align: left;
        transition: max-width 0.3s linear;
        height: 36px;
        max-width: 36px;

        &>span {
            display: inline-block;
            font-size: 1rem;
            font-weight: 700;
            vertical-align: middle;
            white-space: nowrap;
        }

        &:hover {
            padding-right: 9px;
            max-width: 150px;

            @media screen and (max-width: $small) {
                max-width: 36px;
            }
        }

        &:active,
        &:focus {
            max-width: 36px;

            .hidden-text {
                max-width: 36px;
            }
        }
    }

    &--alternate {
        @extend .button-muted-button-round-button;
        padding: 8px 20px;

        img {
            display: none;
        }
    }
}

.button-round-button {
    &:hover {
        background-color: $color-foreground;
        border-color: $color-foreground;
        color: $color-primary;
    }
}

.button-fade {
    display: flex !important;
    align-items: center !important;
    padding: unset !important;
    transition: all 0.7s;
    overflow: hidden;
    max-width: 38px;

    .hidden-text {
        display: flex;
        padding-right: 12px;
        text-align: left;
        transition: all 0.7s;
        max-width: 150px;

        span {
            align-self: center;
            white-space: nowrap;
        }
    }

    &:hover {
        max-width: 150px;

        .hidden-text {
            max-width: 150px;
        }

        @media screen and (max-width: $medium) {
            max-width: 38px;

            .hidden-text {
                max-width: 38px;
            }
        }
    }

    &:active,
    &:focus {
        max-width: 38px;

        .hidden-text {
            max-width: 150px;
        }
    }
}