.adam__product-list {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    gap: 25px;

    .adam__product {
        flex: 0 0 22%;
        width: auto;

        &__image,
        &__logo {
            display: block;
        }

        &__image {
            width: 100%;
        }

        &__logo {
            height: 70px;
        }

        &__discover {
            display: block;
        }
    }
}

@media screen and (max-width: $medium) {
    .adam__product-list {
        .adam__product {
            flex: 0 0 48%;
            margin-bottom: 30px;
        }
    }
}

@media screen and (max-width: $small) {
    .adam__product-list {
        .adam__product {
            flex: 0 0 45%;
            margin-bottom: 30px;
        }
    }
}
@media screen and (max-width: $x-small) {
    .adam__product-list {
        .adam__product {
            flex: 0 0 100%;
            margin-bottom: 30px;
        }
    }
}