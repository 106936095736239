.adam__product-selection {
    background-color: $color-background;
    color: $color-foreground;
    padding: 80px 55px;

    & > .container {
        display: flex;
        align-items: stretch;
        flex-flow: row nowrap;
        justify-content: space-between;
    }

    &__wizard,
    &__products {
        flex: 1 1 auto;
        position: relative;
        width: 45%;
    }

    &__wizard {
        display: flex;
        flex-flow: column wrap;
        padding: 50px 10px 0 10px;
        margin-bottom: 30px;
        width: 55%;
    }

    &__title {
        border-bottom: 1px solid $color-dark;
        font-size: 20px;
        padding-bottom: 10px;
        margin-bottom: 40px;
        max-width: 75%;
    }

    &__list {
        list-style: none;
        padding: 0;

        & > li {
            cursor: pointer;
            font-size: 30px;
            font-weight: 700;
            margin-bottom: 20px;
            line-height: 1.4;
            display: flex;
            align-items: center;

            & > span {
                @extend .serif;
            }

            &::before {
                content: '';
                color: $color-foreground;
                border: 1px solid $color-dark;
                border-radius: 50%;
                display: inline-block;
                margin-right: 28px;
                vertical-align: baseline;
                height: 22px;
                width: 22px;
            }

            &.item--active {
                color: $color-primary;

                &::before {
                    content: '';
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13.035' height='9.329' viewBox='0 0 13.035 9.329'%3E%3Cpath id='Tracciato_262' data-name='Tracciato 262' d='M13.188,31.112,8.067,25.992l1.414-1.414,3.706,3.706,6.5-6.5L21.1,23.2Z' transform='translate(-8.067 -21.783)' fill='%23fff'/%3E%3C/svg%3E%0A");
                    background-color: $color-primary;
                    border-color: $color-primary;
                    font-size: 16px;
                    margin-right: 24px;
                    text-align: center;
                    height: 26px;
                    width: 26px;
                    background-repeat: no-repeat;
                    background-position: center;
                }
            }
        }
    }

    &__image {
        width: 100%;
    }

    &__link {
        color: $color-foreground;
        margin-top: auto;
        text-decoration: underline;
        font-weight: 600;

        &::after {
            border: solid $color-foreground;
            border-width: 0 2px 2px 0;
            content: '';
            display: inline-block;
            margin-left: 15px;
            padding: 3px;
            transform: rotate(-45deg);
            transition: all 0.3s ease-in-out;
        }

        &:hover {
            color: $color-foreground;

            &::after {
                border: solid white;
                border-width: 0 2px 2px 0;
                margin-left: 35px;
            }
        }
    }

    .adam__product {
        display: block;

        &:hover,
        &:active,
        &:focus {
            text-decoration: none;
        }

        &__item {
            background-color: $color-foreground;
            color: $color-background;
            display: flex;
            min-height: 170px;
            margin-bottom: 25px;
            width: 100%;
        }

        &__image {
            align-items: flex-start;
            background-color: $color-background;
            display: flex;
            flex: 0 0 40%;
        }

        &__description {
            line-height: 1.4;
        }

        &__info {
            flex: 0 0 60%;
            padding: 20px 30px;
        }

        &__logo {
            height: 22px;
            margin-bottom: 5px;
        }

        &__image {
            max-width: 250px;

            img {
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: $medium) {
    .adam__product-selection {
        flex-wrap: wrap;
        padding: 80px 0;

        & > .container {
            flex-direction: column;
        }

        &__wizard,
        &__products {
            width: 100%;
        }

        &__wizard {
            padding: 0;
        }

        &__list {
            & > li {
                font-size: 20px;
                display: flex;

                &::before {
                    margin-top: 5px;
                    margin-right: 15px;
                }
            }
        }

        &__items {

            .adam__products {
                display: flex;
                overflow-x: scroll;

                &::-webkit-scrollbar {
                    height: 4px;
                    border-radius: 5px;
                }

                &::-webkit-scrollbar-track {
                    background: rgba($color: #f1f1f1, $alpha: 0.4);
                }

                &::-webkit-scrollbar-thumb {
                    background: $color-primary;
                }

                &::-webkit-scrollbar-thumb:hover {
                    background: $color-primary;
                }

                a.adam__product {
                    flex: 0 0 100%;
                    margin-left: 20px;

                    &:first-child {
                        margin-left: 0;
                    }
                }
            }
        }

        .adam__product {
            height: unset;
            margin-bottom: 15px;

            &__item {
                flex-direction: row;
            }

            &__info {
                flex: unset;
                padding: 10px 20px;
            }

            &__image {
                display: flex;
                max-width: unset;
            }
        }
    }
}

@media screen and (max-width: $small) {
    .adam__product-selection {
        .adam__product {
            &__item {
                flex-direction: column;
            }

            &__image {
                min-height: 250px;
                display: flex;
                max-width: 100%;
            }
        }
    }
}

@media screen and (min-width: $medium) and (max-width: $x-large) {
    .adam__product-selection {
        &__wizard {
            margin-bottom: 50px;
            width: 35%;
        }
    }
}

@media screen and (min-width: $medium) and (max-width: $large) {
    .adam__product-selection {
        .adam__product {
            height: 140px;
        }
    }
}
