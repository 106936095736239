.adam__services {
    &__items {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 30px;
        grid-row-gap: 30px;
    }

    @media (max-width: $medium) {
        &__items {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    @media (max-width: $x-small) {
        &__items {
            grid-template-columns: 1fr;
        }
    }
}

.adam__service {
    &__image {
        position: relative;
        margin-bottom: 15px;

        & > img {
            display: block;
            height: auto;
            width: 100%;
        }

        &:hover {
            .adam__service__overlay {
                height: 100%;
            }
        }
    }

    &__overlay {
        background-color: rgba(0, 0, 0, 0.5);
        bottom: 0;
        left: 0;
        right: 0;
        height: 0;
        overflow: hidden;
        position: absolute;
        transition: .5s ease;
        width: 100%;
    }

    &__description {
        color: $color-foreground;
        font-size: 16px;
        padding: 30px;
    }

    &__title {
        color: $color-font;
        font-weight: 700;
        font-size: 20px;
        margin-bottom: 0;
    }

    &__info {
        align-content: stretch;
        align-items: flex-start;
        display: flex;
        justify-content: space-between;
    }

    &__subtitle {
        color: $color-primary;
        font-weight: 700;
        font-size: 12px;
    }

    &__link {
        margin-left: 30px;
        text-align: right;

        .button-round-button {
            margin: 0;
        }
    }

    @media (max-width: $x-small) {
        &__description {
            line-height: 18px;
            padding: 15px;
        }
    }
}