.adam__story-list {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    justify-content: center;

    .adam__story {
        display: flex;
        flex-direction: column;
        flex: 0 0 25%;
        margin-bottom: 1.5rem;
        margin-top: 1.5rem;

        &__image__container {
            margin: auto;
        }

        &__image {
            max-width: 300px;
            transition: all 0.2s ease-in-out;
            padding: 0 15px;

            &:hover {
                transform: scale(1.05);
            }
        }

        &__discover {
            background: $color-primary;
            border: 1px solid $color-primary;
            border-radius: 25px;
            color: $color-foreground;
            cursor: pointer;
            margin: auto;
            font-size: 1rem;
            font-weight: 600;
            padding: .75rem 1.25rem;
            vertical-align: middle;
            line-height: 1;
            text-align: center;
            text-decoration: none;
            margin-top: 20px;
            transition: all 0.2s ease-in-out;
            width: fit-content;

            &:hover {
                color: $color-primary;
                background: white;
                border-color: $color-primary;
            }
        }
    }
}

@media screen and (max-width: $medium) {
    .adam__story-list {
        &::-webkit-scrollbar {
            height: 4px;
            border-radius: 5px;
        }

        &::-webkit-scrollbar-track {
            background: rgba($color: #f1f1f1, $alpha: 0.4);
        }

        &::-webkit-scrollbar-thumb {
            background: $color-primary;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: $color-primary;
        }

        .adam__story {
            flex: 0 0 50%;
        }
    }
}

@media screen and (max-width: $small) {
    .adam__story-list {
        justify-content: unset;
        flex-wrap: nowrap;
        overflow-x: scroll;
    }
}

@media screen and (max-width: $x-small) {
    .adam__story-list {

        .adam__story {
            flex: 0 0 100% !important;

            &__image {
                padding: 0;
                width: 100%;
            }
        }
    }
}
