.adam__product-search {
    display: grid;
    grid-template-columns: 250px 1fr;
    grid-column-gap: 30px;

    &__sidebar {
        &__title {
            color: $color-font;
            font-size: 1rem;
            font-weight: 700;
            line-height: 1rem;
            margin-bottom: 20px;
        }
    }

    &__filters {
        padding-left: 10px;
        margin-bottom: 75px;
    }

    &__filter {
        color: $color-dark;
        font-weight: 400;
        line-height: 1rem;
    }

    &__checkbox {
        margin-right: 10px;
    }

    &__links {
        & > a {
            color: $color-font;
            display: block;
            font-weight: 700;
            margin-bottom: 5px;
            text-decoration: underline;

            &::after {
                border-style: solid;
                border-width: 3px 3px 0 0;
                content: "";
                display: inline-block;
                height: 9px;
                position: relative;
                width: 9px;
                transform: rotate(45deg);
                margin-left: 12px;
                vertical-align: baseline;
            }
        }
    }

    &__products {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 30px;
        grid-row-gap: 30px;
    }

    .adam__product {
        &__image {
            display: flex;
            line-height: 1rem;
            position: relative;

            & > img {
                display: block;
                margin: auto;
                width: 100%;
            }
        }

        &__category {
            background-color: $color-primary;
            color: $color-foreground;
            font-size: 14px;
            line-height: 18px;
            padding: 2px 10px;
            position: absolute;
            text-transform: lowercase;
            font-weight: 600;
            top: 10px;
            right: 10px;
        }

        &__logo {
            display: block;
            height: 30px;
            margin: 30px 0 15px;
        }

        &__discover {
            display: block;
            font-weight: 700;
            margin-top: 15px;
            text-decoration: underline;
        }
    }
}

.adam__checkbox {
    $root: &;
    color: $color-dark;
    display: block;
    position: relative;
    cursor: pointer;
    font-weight: 400;
    line-height: 18px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    & > input[type="checkbox"] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked ~ #{$root}__box {
            background-color: $color-dark;

            &:after {
                display: block;
            }
        }
    }

    &__box {
        border: 3px solid $color-dark;
        display: inline-block;
        position: relative;
        margin-right: 10px;
        height: 18px;
        width: 18px;
        vertical-align: middle;

        &:after {
            border: solid $color-foreground;
            border-width: 0 3px 3px 0;
            content: "";
            display: none;
            position: absolute;
            left: 4px;
            top: 0;
            height: 10px;
            width: 5px;
            
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
}

@media screen and (max-width: $large) {
    .adam__product-search { 
        &__products {
            grid-template-columns: repeat(2, 1fr);
            grid-row-gap: 60px;
        }
    }
}

@media screen and (max-width: $medium) {
    .adam__product-search {
        grid-template-columns: unset;
        
        &__filters {
            margin-bottom: 30px;
        }

        &__links {
            margin-bottom: 50px;
            display: flex;
            justify-content: space-around;
        }
    }
}

@media screen and (max-width: $x-small) {
    .adam__product-search { 
        &__products {
            grid-template-columns: repeat(1, 1fr);
            grid-row-gap: 60px;
        }
    }
}