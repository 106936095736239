.adam__download {
    &__title {
        font-size: 1.2rem;
        font-weight: 700;
        margin-bottom: 30px;
    }

    &.button--download {
        background-color: $color-primary;
        border-color: transparent;
        border-radius: 100px;
        color: $color-foreground;
        margin: 0;
        padding: .75rem;

        span {
            line-height: 16px;
        }
    }
}
