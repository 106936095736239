//
// TEXT
//

html * {
    font-family: 'Noto Sans', sans-serif;
}

.text-primary {
    color: $color-primary;
}

.text-white {
    color: $color-foreground;
}

.text-light-grey {
    color: $color-font-grey;
}

.button-round-button {
    @extend .button;
    @extend .round-button;
    transition: 0.3s all;

    &:hover {
        border: 1px solid $color-primary;
    }
}

.indented-text {
    margin-left: 70px;

    @media screen and (max-width: $large) {
        margin-left: 55px;
    }

    @media screen and (max-width: $medium) {
        margin-left: 40px;
    }

    @media screen and (max-width: $small) {
        margin-left: 0px;
    }
}

.container {
    .indented-text {
        margin-left: 5rem;

        @media screen and (max-width: $large) {
            margin-left: 55px;
        }

        @media screen and (max-width: $medium) {
            margin-left: 40px;
        }

        @media screen and (max-width: $small) {
            margin-left: 0px;
        }
    }
}

.button-muted-button-round-button {
    @extend .button;
    @extend .muted-button;
    @extend .round-button;
    background-color: $color-foreground;
    background: $color-foreground;
    border: 1px solid $color-font-grey;
    color: $color-background;
    transition: 0.3s all;

    &:hover,
    &:active,
    &:focus {
        border-color: $color-background;
        background-color: $color-background;
        color: $color-foreground;
    }
}

.button-accent-button-round-button {
    @extend .button;
    @extend .accent-button;
    @extend .round-button;
    transition: 0.3s all;
}

.button-close {
    border: none;
    border-radius: 30px;
    cursor: pointer;
    color: $color-background !important;
    font-weight: 700;
    width: 50px;
    height: 50px;
}

.icon-close {
    background-image: url("data:image/svg+xml,%3Csvg height='20px' viewBox='0 0 365.696 365.696' width='20px' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m243.1875 182.859375 113.132812-113.132813c12.5-12.5 12.5-32.765624 0-45.246093l-15.082031-15.082031c-12.503906-12.503907-32.769531-12.503907-45.25 0l-113.128906 113.128906-113.132813-113.152344c-12.5-12.5-32.765624-12.5-45.246093 0l-15.105469 15.082031c-12.5 12.503907-12.5 32.769531 0 45.25l113.152344 113.152344-113.128906 113.128906c-12.503907 12.503907-12.503907 32.769531 0 45.25l15.082031 15.082031c12.5 12.5 32.765625 12.5 45.246093 0l113.132813-113.132812 113.128906 113.132812c12.503907 12.5 32.769531 12.5 45.25 0l15.082031-15.082031c12.5-12.503906 12.5-32.769531 0-45.25zm0 0'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center center;
    background-color: $color-foreground;
    margin-bottom: 0;

    &:active,
    &:hover,
    &:focus {
        background-color: $color-foreground;
        color: $color-background !important;
        border: none;
    }
}

.button-dark-button-round-button {
    @extend .button;
    @extend .accent-button;
    @extend .round-button;
    background-color: $color-background !important;
    border-color: $color-background !important;
    transition: 0.3s all !important;

    &:hover,
    &:active,
    &:focus {
        border-color: $color-background;
        background-color: $color-background;
    }
}

.salvagnini-red {
    color: $color-foreground;
    font-family: Salvagnini Display, sans-serif;
    font-size: 12vw;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: $color-primary;
    top: 35%;
    line-height: 0.7;
}

.salvagnini-white {
    color: $color-primary;
    font-family: Salvagnini Display, sans-serif;
    font-size: 12vw;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: $color-foreground;
    top: 35%;
    line-height: 0.7;
}

.serif {

    &,
    * {
        font-family: 'Noto Serif', serif !important;
    }
}

.huge-text {
    font-size: 100px;

    @media screen and (max-width: $medium) {
        font-size: 50px;
    }
}

.big-text {
    font-size: 80px;

    @media screen and (max-width: $medium) {
        font-size: 40px;
    }
}

.medium-text {
    font-size: 60px;

    @media screen and (max-width: $medium) {
        font-size: 30px;
    }
}

.small-text {
    font-size: 40px;

    @media screen and (max-width: $medium) {
        font-size: 20px;
    }
}

.text-default {
    color: $color-font !important;
}

.underlined {
    text-decoration: underline;
}

.font-weight-bold {
    font-weight: 600;
}

.small {
    font-size: 13px;
}

a {
    .text-white {
        color: $color-foreground;

        &:hover {
            text-decoration: underline;
            text-decoration-color: $color-foreground;
        }
    }

    .text-grey {
        color: $color-font-grey;

        &:hover {
            text-decoration: underline;
            text-decoration-color: $color-font-grey;
        }
    }

    .text-default {
        color: $color-font;

        &:hover {
            text-decoration: underline;
            text-decoration-color: $color-font;
        }
    }
}

.text-center {
    text-align: center;
}

.background-primary {

    .button-accent-button-round-button,
    .button-dark-button-round-button,
    .button-round-button,
    .button-accent-button-round-button,
    .button-muted-button-round-button {
        color: $color-primary !important;
        border: 1px solid $color-primary !important;
        background-color: white !important;

        &:hover {
            color: white !important;
            border: 1px solid white !important;
            background-color: $color-primary !important;
        }
    }
}

.button--submit {
    color: white !important;
    border: 1px solid $color-primary !important;
    background-color: $color-primary !important;

    &:hover {
        color: $color-primary !important;
        border: 1px solid $color-primary !important;
        background-color: white !important;
    }
}


.column-image {

    .button-accent-button-round-button,
    .button-dark-button-round-button,
    .button-round-button,
    .button-accent-button-round-button,
    .button-muted-button-round-button {
        color: white !important;
        border: 1px solid $color-primary !important;
        background-color: $color-primary !important;

        &:hover {
            color: $color-primary !important;
            border: 1px solid white !important;
            background-color: white !important;
        }
    }
}

.button-red {
    button {
        color: white !important;
        border: 1px solid $color-primary !important;
        background-color: $color-primary !important;

        &:hover {
            color: $color-primary !important;
            border: 1px solid $color-primary !important;
            background-color: white !important;
        }
    }
}

.button {
    -webkit-appearance: none;
    display: inline-block;
    border: 1px solid $primary;
    border-radius: 4px;
    background: $primary;
    color: #fff;
    font-weight: 600;
    font-family: Noto Sans, sans-serif;
    font-size: 1rem;
    text-transform: none;
    padding: 0.75rem 1.25rem;
    margin: 0 0 0.5rem;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    line-height: 1;
    border-radius: 40px
}

.cursor,
.pointer {
    &:hover {
        cursor: pointer;
    }
}

.button-red-white {
    @extend .button;

    &:hover {
        border: 1px solid $primary;
        background-color: white;
        color: $primary;
    }
}

.button-white-red {
    @extend .button;
    background-color: white;
    border: 1px solid white;
    color: $color-font;

    &:hover {
        border: 1px solid white;
        background-color: $primary;
        color: white;
    }
}

.adam__button {

    &.button-white-red,
    &.button-red-white,
    &.button-black-red,
    &.button-white-red-alternative,
    &.button-red-white-alternative {
        padding: .5rem 1.25rem;
    }
}


.button-black-red {
    @extend .button;
    background-color: $color-font;
    border: 1px solid $color-font;
    color: white;

    &:hover {
        border: 1px solid $primary;
        background-color: $primary;
        color: white;
    }
}

.button-white-red-alternative {
    @extend .button-white-red;

    &:hover {
        background-color: $primary;
        color: white;
        border: 1px solid $primary;
    }
}

.button-red-white-alternative {
    @extend .button-red-white;

    &:hover {
        color: $color-primary !important;
        border: 1px solid white !important;
        background-color: white !important;
    }
}