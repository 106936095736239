@mixin container-boxed {
    
    @include small-breakpoint {
        max-width: $small;
    }

    @include medium-breakpoint {
        max-width: $medium;
    }

    @include large-breakpoint {
        max-width: $large;
    }

    @include x-large-breakpoint {
        max-width: $x-large;
    }
}