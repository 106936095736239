// Fonts
$font-family: 'Noto Sans', sans-serif;

// Colors
$color-default: #0a0a0a;
$color-primary: #e10514;
$color-secondary: #f3f3f3;

$color-foreground: #fff;
$color-background: #0a0a0a;
$color-background-alt: #f3f3f3;
$color-background-dark: #2b343c;
$color-background-light: #faf2f0;

$color-border: #767676;
$color-light: #f3f3f3;
$color-dark: #333;

$color-font: #0a0a0a;
$color-font-grey: #a4a4a4;
$color-font-light-grey: #a5a5a5;

$color-success-icon: #34d399;
$color-success-text: #065f46;
$color-success-background: #ecfdf5;

$color-warning-icon: #fbbf24;
$color-warning-text: #b45309;
$color-warning-background: #fffbeb;

$color-error-icon: #f87171;
$color-error-text: #a12c2c;
$color-error-background: #fef2f2;

$color-info-icon: #60a5fa;
$color-info-text: #0e42d5;
$color-info-background: #eff6ff;

// Responsive breakpoints
$x-small: 600px;
$small: 768px;
$medium: 1024px;
$large: 1200px;
$x-large: 1400px;



// Mobile heading font size
$h1-mobile                : 3rem                                       !default;
$h2-mobile                : 1.9rem                                     !default;
$h3-mobile                : 1.8rem                                     !default;
$h4-mobile                : 1.7rem                                     !default;
$h5-mobile                : 1.6rem                                     !default;
$h6-mobile                : 1.5rem                                     !default;

// Heading font size
$h1                       : 2.5rem                                     !default;
$h2                       : 2.25rem                                    !default;
$h3                       : 2rem                                       !default;
$h4                       : 1.75rem                                    !default;
$h5                       : 1.5rem                                     !default;
$h6                       : 1.25rem                                    !default;
