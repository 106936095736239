body {
  font-size: inherit;
}

// .container .full-container {
// 	padding: 0;
// }
// @include only-small-breakpoint {
// 	.full-container .container {
// 		padding: 0;
// 	}
// }

// wrapper è la classe usata da Proteinic.Adam.NodeTypes:Content.OneColumn
.wrapper {
  // Create a new stacking context
  display: flex;
  // Make child of .wrapper span the full breadth
  .neos-contentcollection {
    width: 100%;
  }
}

// @include large-breakpoint {

#{$has-background} {
  padding: $background-padding;
}

.no-v-padding {
  #{$has-background} {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.no-h-padding {
  #{$has-background} {
    padding-left: 0;
    padding-right: 0;
	.two-columns{
		.adam-text{
			width: 90%;
		}
	}
  }
}

.img-left{
	.adam-image{
		text-align: left;
	}
}

.img-right{
	.adam-image{
		text-align: right;
	}
}


.align-items-center{
  #{$has-background} {
    align-items: inherit;
  }
}

.flex-row {
  align-items: inherit;
}

main > .neos-contentcollection > .full-container > #{$has-background} {
  padding-left: 0;
  padding-right: 0;
  > .flex-row {
    margin: 0;
    padding: 0 $container-padding - $padding;
  }
}

// .neos-contentcollection {
// 	display: flex;
// 	flex-direction: column;
// }

@include only-small-breakpoint {
  .flex-large:last-child {
    margin-bottom: 0;
  }
}

.adam-text-and-image-container {
  #{$has-background} {
    padding: 0;
    .adam-text-and-image__text {
      padding: $background-padding;
      padding-left: 0;
    }
    .adam-text-and-image--image-right .adam-text-and-image__text {
      padding-left: $background-padding;
      padding-right: 0;
    }
  }
}

.twoThirdsRight .column-one,
.twoThirdsLeft .column-two {
  width: 40%;
}

.twoThirdsRight .column-two,
.twoThirdsLeft .column-one {
  width: 60%;
}

@media (min-width: 800px) {
  .twoThirdsRight > .flex-small.two-columns-column,
  .twoThirdsLeft > .flex-small.two-columns-column {
    flex-basis: unset !important;
  }
}
