.adam__image__tabs {
    position: relative;

    &__pills {
        display: flex;
        flex-direction: row;
        margin-bottom: 1rem;
        flex-wrap: wrap;
        position: absolute;
        bottom: 10%;
        width: 100%;
        justify-content: center;
    }

    &__pill {
        border: 1px solid transparent;
        border-radius: 40px;
        color: $color-primary;
        cursor: pointer;
        display: inline-block;
        font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica, Arial, sans-serif;
        font-size: 1rem;
        font-weight: 600;
        padding: .5rem 1.25rem;
        line-height: 1;
        margin: .5rem .5rem .5rem 0;
        transition: all .3s;
        text-align: center;
        text-decoration: none;
        text-transform: none;
        vertical-align: middle;
        -webkit-appearance: none;
        text-transform: capitalize;

        &.item--active {
            background: $color-primary;
            border: 1px solid $color-primary;
            color: $color-foreground;
        }

        &__link {
            @extend .adam__image__tabs__pill;

            &:hover {
                color: $color-primary;
                text-decoration: none;
            }
        }
    }

    &__image__pills {
        display: flex;
        flex-direction: row;
        margin-bottom: 1rem;
        flex-wrap: wrap;

        .adam__image__tabs__image__pill {
            position: absolute;
            opacity: 0;
            width: 100%;
            transition: opacity 0.5s;

            img {
                width: 100%;
            }

            &.item--active {
                position: relative;
                opacity: 1;
            }

            .adam__image__text__container {
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;

                .salvagnini-red {
                    font-size: 8vw !important;
                }

                .medium-text {
                    font-size: 2.3vw !important;
                }

                .production {
                    position: absolute;
                    left: 60%;
                    top: 30%;

                    .text-primary {
                        position: absolute;
                        left: 78%;
                        top: 20px;
                        line-height: 37px;
                    }

                    .description {
                        position: absolute;
                        top: 140%;
                        color: #333 !important;
                        font-size: 1.1vw;
                        width: 270px;
                        left: 78%;
                    }
                }

                .subsidiary {
                    &.usa {
                        position: absolute;
                        left: 11%;
                        top: 50%;

                        .text-primary {
                            position: absolute;
                            left: 80%;
                            top: 8%;
                        }

                        .description {
                            position: absolute;
                            top: 160%;
                            font-size: 1.1vw;
                            width: 270px;
                            left: 75%;
                            color: #333 !important;
                        }
                    }

                    &.europe {
                        position: absolute;
                        left: 54%;
                        top: 26%;

                        .text-primary {
                            position: absolute;
                            left: -30%;
                            top: 40%;
                        }

                        .description {
                            position: absolute;
                            top: 120%;
                            font-size: 1.1vw;
                            width: 270px;
                            right: -30%;
                            color: #333 !important;
                        }
                    }

                    &.asia {
                        position: absolute;
                        left: 83%;
                        top: 40%;

                        .text-primary {
                            position: absolute;
                            left: -50%;
                            top: 25%;
                        }

                        .description {
                            position: absolute;
                            top: 130%;
                            font-size: 1.1vw;
                            width: 270px;
                            left: -110%;
                        }
                    }
                }

                .services {
                    position: absolute;
                    left: 20%;
                    top: 20%;

                    .text-primary {
                        position: absolute;
                        left: 88%;
                        width: 100%;
                        top: 25%;
                        line-height: 1.2;
                    }

                    .description {
                        position: absolute;
                        top: 140%;
                        font-size: 1vw;
                        width: 260px;
                        left: 85%;
                    }
                }
            }
        }
    }

    .adam__image__tabs__pills__mobile {
        display: none !important;

        &__link {
            text-transform: capitalize;
        }
    }
}

@media screen and (max-width: $medium) {
    .adam__image__tabs {
        &__image__pills {
            .adam__image__tabs__image__pill {
                .adam__image__text__container {

                    .salvagnini-red {
                        font-size: 12vw !important;
                    }

                    .medium-text {
                        font-size: 4vw !important;
                    }

                    .production,
                    .services,
                    .subsidiary {
                        .description {
                            font-size: 1.7vw !important;
                        }

                        &.usa,
                        &.asia,
                        &.europe {
                            .description {
                                font-size: 1.7vw !important;
                            }
                        }
                    }

                    .services {
                        .text-primary {
                            width: 120%;
                        }
                    }

                    .production {
                        .text-primary {
                            line-height: 31px;
                        }
                    }

                    .subsidiary {
                        &.europe {
                            .text-primary {
                                top: 43%;
                            }
                        }

                    }
                }
            }
        }
    }
}



@media screen and (max-width: $small) {
    .adam__image__tabs {

        position: relative;

        &__pills {
            position: relative;
            flex-direction: column;
            padding: 15px 30px;
            width: auto;
            margin-top: -1.5rem;
            margin-right: 20px;
            margin-left: 20px;
            background-color: $color-primary;
            margin-bottom: 0rem;
        }

        &__pill {
            color: white;
            width: fit-content;
            margin: auto;
            padding: 0.625rem 1.25rem;
            margin-bottom: 15px;

            &.item--active {
                background: white;
                border: 1px solid white;
                color: $color-primary;
            }

            &__link {
                color: white;

                &:hover,
                &:active,
                &:focus {
                    color: $color-primary;
                    background-color: white;
                }
            }

        }

        &__image__pills {
            display: flex;
            flex-direction: column;
            margin-bottom: 1rem;
            flex-wrap: wrap;

            .adam__image__tabs__image__pill {
                position: absolute;
                opacity: 0;
                transition: opacity 0.5s;

                img {
                    width: 100%;
                }

                &.item--active {
                    position: relative;
                    opacity: 1;
                }
            }

            .adam__image__tabs__image__pill {
                .adam__image__text__container {

                    .production,
                    .services,
                    .subsidiary {
                        .description {
                            width: 170px !important;
                            top: 160%;
                            color: #333 !important;
                        }

                        &.usa,
                        &.asia,
                        &.europe {
                            .description {
                                width: 160px !important;
                            }
                        }

                    }

                    .production {
                        left: 35%;
                        top: 25%;

                        .text-primary {
                            line-height: 28px;
                        }

                        .description {
                            top: 170%;
                        }
                    }

                    .services {
                        .text-primary {
                            top: 18%;
                            left: 93%;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $x-small) {
    .adam__image__tabs {
        &__image__pills {
            .adam__image__tabs__image__pill {
                .adam__image__text__container {
                    .production {
                        .text-primary {
                            line-height: 18px;
                        }
                    }
                }
            }
        }
    }
}