.adam__form {

    &.form--product-webinar,
    &.form--webinar {
        padding: 2.25rem 3rem;
        background-color: $color-foreground;
        border: 2px solid $color-primary;
    }

    &.form--product-contact {
        .adam__form__content {
            &__flex {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-column-gap: 20px;
                grid-row-gap: 20px;

            }
        }
    }

    .adam__hint {
        color: $color-primary;
        font-size: .8125rem;
        padding: 0 15px;
        text-align: center;

        &.hint--hidden {
            display: none;
        }
    }

    h5 {
        margin-bottom: 10px;
    }

    label {
        color: $color-font-light-grey;
        font-weight: 500;
        font-size: 14px;
    }

    select {
        line-height: 1.5;
    }

    input,
    select {
        margin-top: 5px;
        padding: 12px 20px;

        &:active,
        &:focus,
        &:target {
            box-shadow: none;
            border-color: unset;
        }
    }

    .form__subtitle {
        font-size: 18px;
    }

    .adam__input {
        display: block;
        margin-bottom: 15px;

        &__checkbox {
            margin-right: 8px;
        }
    }

    .adam__button {
        margin-top: 1.5rem;
        padding: 0.75rem 2.75rem;
    }

    .adam__form__privacy,
    .adam__input__checkbox {
        label {
            font-size: 13px !important;
            color: $color-font;

            a {
                font-weight: 700;
                text-decoration: underline;
            }
        }

        .adam__input {
            cursor: pointer;
            display: block;
            position: relative;
            padding-left: 35px;
            margin-bottom: 15px;
            margin-top: 15px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;

            input {
                cursor: pointer;
                position: absolute;
                opacity: 0;
                height: 0;
                width: 0;
            }
        }

        .checkmark {
            background-color: $color-foreground;
            border: 2px solid #eee;
            border-radius: 5px;
            position: absolute;
            height: 22px;
            left: 0;
            top: 0;
            transition: all 0.2s;
            width: 22px;

            &:after {
                content: "";
                position: absolute;
                display: none;
            }
        }

        .adam__input {
            &:hover input~.checkmark {
                background-color: #ccc;
            }

            input:checked~.checkmark {
                background-color: $color-primary;

                &:after {
                    display: block;
                }
            }

            .checkmark:after {
                left: 7px;
                top: 3px;
                width: 5px;
                height: 10px;
                border: solid $color-foreground;
                border-width: 0 3px 3px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }
    }
}

.adam__alert {
    border: 1px solid;
    border-radius: .5rem;
    font-size: .9375rem;
    padding: 15px;
    margin: 20px auto 15px auto;
    width: fit-content;

    &.alert--info {
        background-color: $color-info-background;
        border-color: $color-info-icon;
        color: $color-info-text;
    }

    &.alert--success {
        background-color: $color-success-background;
        border-color: $color-success-icon;
        color: $color-success-text;
    }

    &.alert--warning {
        background-color: $color-warning-background;
        border-color: $color-warning-icon;
        color: $color-warning-text;
    }

    &.alert--error {
        background-color: $color-error-background;
        border-color: $color-error-icon;
        color: $color-error-text;
    }

    &.alert--hidden {
        display: none;
    }
}

.form-border {
    >.adam__form {
        >form {
            padding: 2.25rem 3rem;
            background-color: $color-foreground;
            border: 2px solid $color-primary;
        }
    }
}

@media screen and (max-width: $medium) {
    .adam__form {

        &.form--product-webinar,
        &.form--webinar {
            padding: 2.25rem 2rem;
        }

        h5 {
            font-size: 1.3rem;
        }
        &__content {
            &__flex {
                grid-template-columns: repeat(1, 1fr) !important;
                grid-row-gap: 0 !important;
            }
        }


        .adam__form__privacy,
        .adam__input__checkbox {
            .adam__input {
                margin-bottom: 0px;
            }
        }

    }
}

@media screen and (max-width: $x-small) {
    .adam__form {
        &__content {
            &__flex {
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                grid-column-gap: 20px;
            }
        }
    }
}
