.adam__sticky {
    position: sticky;
    top: -1px;
    z-index: 90;

    &.sticky--navbar {
        border-bottom: 1px solid $color-light;
        background-color: $color-foreground;
        padding: 10px 30px;

        &>.adam-column {
            @include container-boxed;

            align-items: center;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            margin: 0 auto;

            &>section {
                width: 250px;

                .adam-image {
                    height: 24px;
                    text-align: left;

                    &__image {
                        width: auto;
                    }
                }

                &.adam__scrollspy {
                    flex-grow: 1;
                }

                &.section--buttons {
                    .adam__button img {
                        width: 34px;
                    }
                }
            }
        }

        &.paper {
            &>.adam-column>section {
                width: 20%;
            }
        }
    }

    .adam__scrollspy {
        &__content {
            display: flex;
            justify-content: space-around;
            width: 100%;

            &>li {
                list-style-type: none;
            }

            a {
                color: $color-font-grey;
                font-weight: 700;
                text-decoration: none;
                transition: color 0.2s;
                white-space: nowrap;

                &:hover {
                    color: $color-font;
                }
            }
        }
    }
}

@media screen and (max-width: $large) {
    .adam__sticky.sticky--navbar {
        &>.adam-column {
            &>section {
                width: 50%;

                &.adam__scrollspy {
                    display: none;
                }
            }
        }
    }
}

@media screen and (max-width: $medium) {
    .adam__sticky {
        &.sticky--navbar {
            &>.adam-column {
                .adam__modal__content {
                    section {
                        width: 100% !important;
                    }
                }
            }

            &.paper {
                &>.adam-column>section {
                    width: 50%;
                }
            }
        }
    }
}