.container {
    .adam__machine {
        padding: 55px 0;
    }
}

.adam__machine {
    background-color: $color-background;
    color: $color-foreground;

    padding: 55px 10%;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-between;

    &__info {
        flex: 1 1 auto;
        padding: 0 30px;
        position: relative;
        width: 40%;
    }

    &__content {
        flex: 1 1 auto;
        position: relative;
        width: 60%;
        margin: auto;
    }

    &__title {
        font-size: 34px;
        font-weight: 700;
        line-height: 40px;
        margin: 30px 0;
        max-width: 70%;
    }

    &__image {
        width: 100%;
    }

    &__pin {
        $el-pin: &;

        background-color: $color-primary;
        font-size: 14px;

        border-radius: 100px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        text-align: center;
        height: 30px;
        min-width: 30px;
        box-shadow: 0 0 0 4px rgba($color-foreground, 0.4), 0 0 0 8px rgba($color-foreground, 0.2);

        &__icon {
            font-size: 1.33rem;
            font-weight: 700;
            transition: 0.25s ease-in;
            content: "";
            width: 27px;
            height: 27px;
            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 25.3.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Livello_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 26 26' style='enable-background:new 0 0 26 26;' width='27' height='27' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Crect x='11.7' y='6.3' class='st0' width='2.7' height='13.5'/%3E%3Crect x='6.3' y='11.7' class='st0' width='13.5' height='2.7'/%3E%3C/svg%3E%0A");
        }

        &__title {
            overflow: hidden;
            transition: 0.5s ease-in;
            white-space: nowrap;
            width: 0;
            font-weight: 600;
        }

        &.item--active {
            box-shadow: none;
            z-index: 1;

            #{$el-pin}__icon {
                margin-left: 5px;
                margin-right: 2px;
                transform: rotate(45deg);
            }

            #{$el-pin}__title {
                padding: 0 10px 0 0;
                width: auto;
            }
        }
    }

    &__pins {
        padding-left: 35px;
        padding-right: 45px;
        max-width: 500px;

        .adam__pin {
            border-bottom: 1px solid $color-border;

            &:last-child {
                border-bottom: none;
            }

            &__title {
                background: none;
                border: none;
                font-weight: 600;
                opacity: 0.5;
                margin: 0;
                padding: 15px 0;
                text-align: left;
                width: 100%;
                position: relative;

                &::after {
                    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 25.3.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Livello_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 26 26' style='enable-background:new 0 0 26 26;' width='27' height='27' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Crect x='11.7' y='6.3' class='st0' width='2.7' height='13.5'/%3E%3Crect x='6.3' y='11.7' class='st0' width='13.5' height='2.7'/%3E%3C/svg%3E%0A") !important;
                    background-repeat: no-repeat;
                    background-position: center center !important;
                    content: "";
                    right: 15px;
                    top: 13px;
                    position: absolute;
                    flex-shrink: 0;
                    margin-left: auto;
                    height: 1.25rem;
                    width: 1.25rem;
                    transition: transform .2s ease-in-out;
                }

                &.item--active {
                    opacity: 1;

                    &::after {
                        transform: rotate(-225deg);
                        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 25.3.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Livello_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 26 26' style='enable-background:new 0 0 26 26;' width='27' height='27' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Crect x='11.7' y='6.3' class='st0' width='2.7' height='13.5'/%3E%3Crect x='6.3' y='11.7' class='st0' width='13.5' height='2.7'/%3E%3C/svg%3E%0A") !important;
                        // background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 25.3.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Livello_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 26 26' style='enable-background:new 0 0 26 26;' width='27' height='27' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Crect x='6.3' y='11.7' class='st0' width='13.5' height='2.7'/%3E%3C/svg%3E%0A") !important;
                    }
                }
            }

            &__content {
                font-size: 14px;
                margin-top: 10px;
                padding-left: 15px;
                padding-bottom: 15px;
                opacity: 0.8;
            }
        }
    }

    // Background
    .background-white {
        padding: 0;

        .adam {
            &__machine {
                background-color: $color-foreground;
                color: $color-font !important;

                &__pin {
                    color: $color-font;

                    &__title {
                        color: $color-foreground;
                    }
                }
            }

            &__pin {
                &__title {
                    opacity: 0.6;
                    color: $color-font;
                }
            }
        }
    }

    .background-alternate {
        padding: 0;

        .adam {
            &__machine {
                background-color: $color-background-alt;
                color: $color-font;

                &__pin {
                    color: $color-font;

                    &__title {
                        color: $color-foreground;
                    }
                }
            }

            &__pin {
                &__title {
                    opacity: 0.6;
                    color: $color-font;
                }
            }
        }
    }

    .background-cream {
        padding: 0;

        .adam {
            &__machine {
                background-color: $color-background-light;
                color: $color-font !important;

                &__pin {
                    color: $color-font;

                    &__title {
                        color: $color-foreground;
                    }
                }
            }

            &__pin {
                &__title {
                    color: $color-font;
                    opacity: 0.6;
                }
            }
        }
    }
}

@media screen and (max-width: $large) {
    .adam__machine {
        &__pins {
            padding-left: 0;
        }
    }
}

@media screen and (max-width: $medium) {
    .adam__machine {
        display: flex;
        flex-direction: column;

        &__info {
            flex: unset;
            padding: 0;
            margin-bottom: 1.5rem;
            width: 100%;
        }

        &__content {
            flex: unset;
            width: 100%;
        }

        &__title {
            max-width: 100%;
        }

        &__pins {
            padding: 0;
            max-width: none;
        }
    }
}

@media screen and (max-width: $small) {
    .adam__machine {
        display: flex;
        flex-direction: column;

        &__content {
            overflow: hidden;
        }
    
        &__image {
            transform: scale(1.2);
        }
    }
}

@media screen and (max-width: $x-small) {
    .adam__machine {
        &__pin {
            display: none;
        }
    }
}
