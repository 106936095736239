.adam__lottie {
    &.lottie--mobile {
        display: none;
    }

    svg,
    canvas {
        min-height: 100%;
    }
}

.roboformer-logo {
    canvas {
        width: auto;
    }
}

@media screen and (max-width: $large) {
    .adam__lottie {
        height: 100%;
    }
}

@media screen and (max-width: $medium) {
    .product-cover {
        &-animation {
            .adam__lottie {
                width: fit-content;

                svg {
                    height: 100px;
                    width: auto;
                }
            }
        }
    }
}

@media screen and (max-width: $small) {
    .adam__lottie {
        &.lottie--mobile {
            display: block;

            &+.adam__lottie.lottie--desktop {
                display: none;
            }
        }
    }

    .roboformer-logo {
        canvas {
            width: 100%;
        }
    }
}