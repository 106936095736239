.adam__text-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    // Modificato a 90%, andava spesso in overflow-x la pagina intera
    width: 90%;
    height: 90%;
    display: flex;
    @extend .container;
    padding: $container-padding $container-padding * 2;
    pointer-events: none;


    .adam__text-wrapper__text {
        text-align: left;
        margin-top: auto;
        margin-bottom: auto;
        margin-left: auto;
        margin-right: auto;
        > * {
            pointer-events: all;
        }
    }

    .adam__text-wrapper__text--center {
        width: $large;
    }

    .adam__text-wrapper__text--top {
        margin-top: 0;
    }
    .adam__text-wrapper__text--bottom {
        margin-bottom: 0;
    }
    .adam__text-wrapper__text--left {
        margin-left: 0;
    }
    .adam__text-wrapper__text--right {
        margin-right: 0;
    }
}

.adam-image {
    position: relative;
    text-align: center;
    line-height: 0;
    
    // .linkable-image{
    //     &:hover{
    //         .adam-image__image{
    //             transform: scale(1.05);
    //         }
    //     }
    // }

    .adam-image__image {
        width: 100%;
        height: 100%;
        transition: all 0.3s ease-in-out;

        img {
            width: 100%;
        }
    }
    .adam-image__image--fixed {
        width: auto;
        img {
            width: auto;
            max-width: 100%;
        }
    }

    > a {
        line-height: 0;
        display: block;
    }


}



// .adam-image {
// 	@extend %row-margin;
// }
// .adam-column, .glide__slide {
// 	.adam-image {
// 		margin-left: 0;
// 		margin-right: 0;
// 	}
// }
