// Stili custom
@font-face {
    font-family: 'Salvagnini Display';
    src: url('../Fonts/SalvagniniDisplayRegular.eot');
    src: url('../Fonts/SalvagniniDisplayRegular.eot?#iefix') format('embedded-opentype'),
        url('../Fonts/SalvagniniDisplayRegular.woff2') format('woff2'),
        url('../Fonts/SalvagniniDisplayRegular.woff') format('woff'),
        url('../Fonts/SalvagniniDisplayRegular.ttf') format('truetype'),
        url('../Fonts/SalvagniniDisplayRegular.svg#SalvagniniDisplayRegular') format('svg');
    font-display: swap;
    font-style: normal;
    font-weight: 500;
}

@supports (-webkit-overflow-scrolling: touch) {
    body * {
        background-attachment: scroll;
    }
}

body {
    font-family: $font-family;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    line-height: 1.2;
    display: inline-block;
}

.noScroll {
    position: fixed;
    overflow-y: scroll;
    width: 100%;
}

.bold {
    font-weight: 700;
}

.capo-testo {
    .adam-text {
        max-width: 550px;
    }
}

.fade-overlay {
    position: relative;

    &:before {
        background: linear-gradient(180deg, rgba(34, 193, 195, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
        content: "";
        height: 100%;
        position: absolute;
        width: 100%;
        z-index: 10;
    }

    .column-image * {
        position: relative;
        z-index: 12;
    }
}

.dark-overlay {
    background-color: $color-background;
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.5s;
    height: 100%;
    width: 100%;
    z-index: -10;

    &.active {
        display: block;
        opacity: 0.6;
        z-index: 100;
    }
}

a,
p,
span {
    line-height: 1.5;
}

.mb-0 {
    margin-bottom: 0;
}

[class*="background-"] {
    padding: 0;
}

// Toglie padding da text-wrapper: cause overflow x in pagine
footer {
    .float-items-right {
        .adam__text-wrapper {
            padding: 0;
        }
    }
}

.adam-text {
    ul {
        padding-left: 20px;
    }
}

.adam__product-list {
    .adam__product__logo {
        padding-top: 25px;
        padding-bottom: 15px;
    }

    .adam__product__discover {
        margin-top: 15px;
    }
}

.adam__product-selection__products {
    .adam__product {
        overflow: hidden;
    }
}

@each $name,
$value in $item-padding-scale {
    .item-padding-scale-#{$name} {
        .adam-column-row {
            .neos-contentcollection {
                section {
                    margin-right: $value;

                    &:last-child {
                        margin-right: unset;
                    }
                }
            }
        }
    }
}

.second-level-menu {
    max-width: 960px !important;
    margin: auto;

    .adam-text {

        p,
        a,
        span,
        strong {
            transition: all 0.3s;

            &:hover {
                color: $color-font;
                text-decoration: none;
            }
        }
    }
}

.search-box {
    margin: auto;
    max-width: 1000px !important;
}

.product {
    .adam__accordion__item {
        border: unset;
        border-bottom: 2px solid $color-background;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        &:last-child {
            border-bottom: none;
        }

        &__title {
            background-color: $color-foreground;
            border: none;
            color: $color-font;
            font-weight: 700;
            justify-content: space-between;
            line-height: 18px;

            &::after {
                background-color: $color-font;
                background-position: 100% 100%;
                border-radius: 50%;
                width: 27px;
                height: 27px;
                margin-left: 15px;
                margin-right: 0;
            }
        }
    }
}

.product-table {
    table {
        tbody {

            td,
            th {
                padding: 1.25rem;
            }

            tr:first-child {
                background-color: $color-background-alt;
            }
        }
    }

    &.with-border {
        table {
            border: 1px solid #dedede;
        }
    }
}

.intro-buttons {
    margin-top: -120px;
    padding-bottom: 8rem !important;
    position: relative;
    z-index: 10;

    .button-round-button:hover {
        border: 1px solid white !important;
    }
}

.dark-boxed-container {
    padding: 30px;
    background-color: #0a0a0a;
    position: relative;

    &::before {
        background-color: rgba(0, 0, 0, 0.5);
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }
}

.dark-img-overlay {
    background-color: #0a0a0a;
    position: relative;

    &::before {
        background-color: rgba(0, 0, 0, 0.4);
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.white-boxed-container {
    padding: 30px;
    background-color: white;
}

.neos-backend {
    .dark-boxed-container {
        z-index: 2000;

        &::before {
            z-index: -1;
        }
    }
}

.img-homepage {
    .adam__text-wrapper {
        margin-left: 100px;

        .salvagnini-red {
            font-size: 10vw;
        }
    }
}

.plus {
    .adam-text {
        p span {
            position: relative;

            &::after {
                content: "";
                background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 26.3.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Livello_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 65 65' style='enable-background:new 0 0 65 65;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:none;stroke:%23FFFFFF;stroke-width:1.5;stroke-miterlimit:10;%7D%0A%3C/style%3E%3Cpolygon class='st0' points='42.6,62.8 22.3,62.8 22.3,42.6 2,42.6 2,22.3 22.3,22.3 22.3,2 42.6,2 42.6,22.3 62.8,22.3 62.8,42.6 42.4,42.6 '/%3E%3C/svg%3E%0A");
                background-repeat: no-repeat;
                font-size: 90px;
                position: absolute;
                top: 0;
                height: 50px;
                width: 50px;
            }
        }
    }
}

.sub-language {
    font-size: 80%;
}

.plyr {
    &--video {
        .plyr__video-wrapper {
            height: 0;
            padding-top: 56.25%;
            position: relative;
            width: 100%;

            .neos-plyr {
                height: auto;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
            }
        }
    }

    &__controls {
        opacity: 0 !important;
        transition: all 0.3s !important;
    }
}

.jonnitto-plyr-container:hover {
    .plyr__controls {
        opacity: 1 !important;
    }
}

.flatpickr-current-month {
    padding: 2px !important;
}

.salvagnini-index {
    position: relative;
    z-index: 6;
}

.salvagnini-negative-margin-top {
    margin-top: -300px;
}

section {
    &.section--fade {
        opacity: 0;
        transform: translateY(100px);
        transition: all 0.3s;
    }

    &.section--unfade {
        opacity: 1;
        transform: translateY(0);
        transition: all 0.3s;
    }

    &.section--grid-6 {
        &>.wrapper>.neos-contentcollection {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-column-gap: 0;
            grid-row-gap: 30px;
        }
    }
}

.glide__slide,
.adam__history,
.adam__history__items {
    section.section--fade {
        transform: translateY(0);
    }
}

// Senza questa riga lo scroll dell'history si rompe
.adam__history,
.adam__history__items,
.adam__scrollprogress,
.adam__scrollprogress__items,
.adam__sticky {

    section.section--fade,
    section.section--unfade {
        transform: unset !important;
        opacity: 1 !important;
    }
}

.custom-container {
    @extend .container;
    max-width: 1700px !important;
}

.custom-small-container {
    @extend .container;
    max-width: 1200px !important;
}

.salvagnini-installation-info-img {
    img {
        max-width: 110px !important;
    }
}

.wrap-footer {
    a:hover {
        text-decoration: none;
    }
}

.hover-red {
    .button-muted-button-round-button {
        &:hover {
            background-color: $primary;
            color: white;
            border: 1px solid $primary;
        }
    }
}

.icone-social-topbar {
    min-width: 155px;

    .neos-contentcollection {
        justify-content: space-between !important;
    }

    .full-container {
        margin-right: 0;
    }

    img {
        &:hover {
            filter: brightness(0) invert(1);
        }
    }
}

.fix-video {
    video {
        width: 101% !important;
    }
}

.video-sliders {
    .slide {
        .slide__track {
            .glide__slide {
                section {
                    @extend .align-items-center;

                    .two-columns {
                        .column-two {
                            align-self: flex-start;
                        }
                    }
                }
            }
        }
    }
}

.numbered-list {
    ol {
        padding-left: 65px;
        counter-reset: item;
        list-style-type: none;

        li {
            margin-bottom: 1.2rem;
            position: relative;
            line-height: 16px;

            &::marker {
                color: $primary;
            }

            &::before {
                content: counter(item) " ";
                counter-increment: item;
                position: absolute;
                left: -65px;
                padding: 10px 15px;
                line-height: 1;
                border-radius: 70;
                border: 1px solid #dedede;
                border-radius: 50px;
                font-size: 20px;
                font-weight: 600;
                color: $primary;
            }

            &::after {
                content: "";
                width: 1px;
                background-color: #dedede;
                height: calc(100% - 22px);
                position: absolute;
                left: -43px;
                top: 41px;
            }

            &:last-child {
                &::after {
                    display: none;
                }
            }

            .h6 {
                font-size: 22px;
                line-height: 2;
            }

            a,
            .text-light-grey {
                font-size: 14px;
                line-height: 1.3;
            }

            .text-light-grey {
                color: $color-border;
            }
        }
    }
}

.red-list {
    ul {
        li {
            position: relative;
            padding-top: 1rem;
            padding-bottom: 1rem;
            font-weight: 600;

            &::marker {
                color: $primary;
            }

            &:after {
                content: "";
                // background-color: #b3b3b3;
                background-color: #dedede;
                bottom: 0;
                left: -20px;
                position: absolute;
                height: 1px;
                width: 100%;
            }
        }
    }
}

.custom-height {
    >.adam__lottie {
        height: 100% !important;
    }
}

.product-cover {
    &.normal-menu {
        .column-image {
            >.neos-contentcollection {
                padding-top: 0 !important;
            }
        }
    }

    .product-cover-animation-column {
        section {
            margin-left: 0 !important;
            padding-left: 0 !important;
        }
    }

    .column-image {
        min-height: 620px;
        display: grid;
        background-position: 300px calc(50% + 30px) !important;

        &>.neos-contentcollection {
            align-self: self-end;

            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            padding: 60px 0;

            &>.product-cover-animation {
                padding-top: 6rem;
            }
        }

        .description {
            padding-bottom: 4rem;
        }
    }

    &-animation {
        .adam__lottie {
            width: fit-content;

            svg {
                height: 100px !important;
            }
        }
    }
}

.cover-height {
    .column-image {
        max-height: 620px;
    }
}

.three-columns-footer {
    .three-columns {
        .three-columns-column:nth-child(1) {
            flex-basis: 50%;
        }

        .three-columns-column:nth-child(2),
        .three-columns-column:nth-child(3) {
            flex-basis: 25%;
        }
    }

    ul {
        padding-left: 0;
        list-style-type: none;

        li {
            a {
                color: $color-font;
                margin-bottom: 5px;

                &:hover {
                    color: $color-font;
                    text-decoration: none;
                }
            }
        }
    }
}

.category-cover {
    >.column-image {
        >.neos-contentcollection {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
        }
    }
}

.lottie-features {
    .adam__lottie {
        min-height: 60px;
    }
}

.roboformer-logo {
    svg {
        width: auto !important;
    }
}

// Menu normale
#header>.neos-contentcollection>section {
    &>.column-image {
        min-height: 700px;
    }
}

// Menu invertito
.inverted-menu {
    #header>.neos-contentcollection>section {
        &>.column-image {
            min-height: 788px;
        }
    }
}

@supports (-webkit-touch-callout: none) {
    .product-cover {
        .product-cover-animation {
            padding-left: 0 !important;
            padding-right: 0 !important;
            width: auto !important;

            .adam__lottie {
                canvas,
                svg {
                    position: relative;
                    left: 0;
                    margin-left: 0;
                    width: unset !important;
                    max-width: 100% !important;
                }
            }
        }
    }
}

.column-center.sustainability {
    margin: auto;
    max-width: 80%;

    >.two-columns {
        .two-columns {
            flex-wrap: nowrap !important;
        }

        .indented-text {
            max-width: 50%;
        }
    }
}

.big-number-sustainability {
    .salvagnini-white,
    .salvagnini-red {
        font-size: 150px;
    }
}

.negative-magin-left {
    margin-left: -25px;
}

// ******* MOBILE *******
@media screen and (max-width: $x-small) {
    .software-column-page {
        >.column-image {
            background-position: 29% 90% !important;
        }
    }

    .salvagnini-installation-info {
        img {
            max-width: 110px !important;
        }

        .adam-text-and-image {
            flex-direction: column;

            &__text {
                margin-left: 0;
                text-align: center;
            }
        }
    }

    .product-cover {
        .column-image {
            background-position: center center !important;
        }

        &-animation {
            padding-top: 5rem !important;
            padding-bottom: 14rem !important;
        }
    }

    .top-bar-menu {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }

    .icone-social-topbar {
        min-width: 120px;
    }

	.column-center.sustainability {
        max-width: 90%;

        >.two-columns {
            .indented-text {
                max-width: 100%;
            }
        }
    }
}

@media screen and (min-width: $x-small) and (max-width: $small) {
    .product-cover {
        .column-image {
            background-position: center center !important;
        }
    }
}

@media screen and (max-width: $small) {
    .adam-image {
        img {
            height: unset !important;
        }
    }

    .two-columns {
        &.boxLeft {
            .column-one {
                padding-right: 1rem;
            }

            .column-two {
                padding-left: 1rem;
            }
        }

        &.boxRight {
            .column-one {
                padding-right: 1rem;
            }

            .column-two {
                padding-left: 1rem;
            }
        }
    }

    .text-center-mobile {
        text-align: center;
    }

    .img-homepage {
        .adam__text-wrapper {
            margin-left: -30px;
        }
    }

    .salvagnini-form-column {
        .column-one,
        .column-two {
            padding-right: 0;
            padding-left: 0;
        }
    }

    section {
        &.section--grid-6 {
            &>.wrapper>.neos-contentcollection {
                grid-template-columns: repeat(1, 1fr);
            }
        }
    }

    .salvagnini-negative-margin-top {
        margin-top: -90px;
    }

    .invert-two-columns-mobile {
        .two-columns {
            .column-one {
                order: 1;
            }
        }
    }

    .remove-margin-top {
        margin-top: unset !important;
        padding-top: 2rem;
    }

    .dark-boxed-container {
        padding: 30px 0;
    }

    .glide__slide {
        padding-bottom: 0;
    }

    .salvagnini-installation-info {
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-bottom: 2rem;

        img {
            max-width: 90px !important;
        }

        .two-columns {
            .two-columns-column {
                flex-basis: 50%;
            }
        }

        .adam-text-and-image {
            .h4 {
                font-size: 1.5rem !important;
            }
        }
    }

    .innovation-salvagnini {
        h5,
        span {
            font-size: 24px;
            text-align: center;
        }
    }

    .custom-small-container {
        .two-columns {
            .column-one,
            .column-two {
                width: 100% !important;
            }
        }
    }

    .column-center.sustainability {
        max-width: 100%;

        >.two-columns {
            >.two-columns-column {
                width: 100%;
            }

            .two-columns {
                flex-direction: row;

                .flex-small {
                    flex-basis: 50% !important;
                }
            }

            .indented-text {
                margin: auto;
            }
        }
    }

    .product-cover {
        .column-image {
            background-position: center center !important;
        }

        &-animation {
            display: flex;
            justify-content: flex-start;
            -webkit-justify-content: flex-start;
            padding-top: 6rem !important;
            padding-bottom: 17rem !important;
        }
    }

    .roboformer-logo {
        svg {
            width: 100% !important;
        }
    }

    .three-columns-footer {
        .three-columns {
            .three-columns-column:nth-child(1) {
                flex-basis: 100%;
            }

            .three-columns-column:nth-child(2),
            .three-columns-column:nth-child(3) {
                flex-basis: 50%;
            }
        }
    }

    .stream-column-page {
        >.column-image {
            background-position: 87% center !important;
        }
    }

    .punching-column {
        >.column-image {
            background-position: 65% 0 !important;
        }
    }

    .sustainability-column-page {
        >.column-image {
            background-position: -230px 0;

            .text-center-mobile {
                .h4 {
                    font-size: 24px;
                }
            }
        }
    }
}

@media screen and (max-width: $medium) {
    
    // Menu invertito
    .inverted-menu {
        #header>.neos-contentcollection>section {
            &>.column-image {
                min-height: 708px;
            }
        }
    }

    // Menu normale
    #header>.neos-contentcollection>section {
        &>.column-image {
            min-height: 644px;
        }
    }

    .fix-stretch-iphone img {
        height: unset !important;
    }

    .ops-column {
        &>.column-image {
            background-color: black !important;
            background-repeat: no-repeat !important;
            background-size: auto !important;

            background-position: center center;
            background-position-x: calc(50% + 390px) !important;
            background-position-y: -140px !important;

            padding-top: 300px !important;
            position: relative;

            &:before {
                content: "";
                background: linear-gradient(180deg, rgba(34, 193, 195, 0) 0%, rgba(0, 0, 0, 0.70) 100%);
                width: 100%;
                height: 100%;
                position: absolute;
                z-index: 10;
                top: 0;
            }

            &>.neos-contentcollection {
                padding-bottom: 0;

                &>section {
                    padding-bottom: 0;
                }
            }

            * {
                position: relative;
                z-index: 12;
            }

            .two-columns-column {
                padding-right: 0;
                padding-left: 0;
                flex-basis: 100% !important;
            }
        }
    }
    
    .category-cover {
        >.column-image {
            >.neos-contentcollection {
                .column-row {
                    .adam__lottie {
                        height: unset !important;

                        svg,
                        canvas {
                            height: 200px !important;
                        }
                    }
                }
            }
        }
    }

    .wrap-footer {
        >.column-row {
            >.adam-column-row {
                >.neos-contentcollection {
                    flex-direction: column;
                }
            }
        }
    }

    .product-creator {
        margin-top: -100px;

        .two-columns {
            .column-one {
                flex-basis: 100%;
            }

            .column-two {
                flex-basis: 0;
            }

            img {
                width: 100%;
                max-width: 260px;
            }
        }
    }

    .two-columns {
        &.boxLeft {
            .column-one {
                padding-right: 2rem;
            }

            .column-two {
                padding-left: 2rem;
            }
        }

        &.boxRight {
            .column-one {
                padding-right: 2rem;
            }

            .column-two {
                padding-left: 2rem;
            }
        }
    }

    .intro-buttons {
        margin-top: unset;
        padding-bottom: 2rem;
        padding-top: 2rem;

        &.negative-margin-large {
            margin-top: -120px !important;
        }
    }

    section {
        &.section--grid-6 {
            &>.wrapper>.neos-contentcollection {
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }

    .table {
        overflow-x: scroll;
    }

    .salvagnini-form-column {
        &.salvagnini-negative-margin-top {
            margin-top: -100px;
        }

        .column-one,
        .column-two {
            flex-basis: 100%;
            width: 100%;
        }
    }

    [class^="background-"] {
        .glide__slide {
            >section:first-child {
                // Se zero si intravedono le altre slide 
                padding-right: 5px;
                padding-left: 5px;
            }
        }
    }

    .product-cover {
        .column-image {
            min-height: 700px;
            background-position: 120px center !important;

            .description {
                // width: 60%;

                .column-one {
                    flex-basis: 100%;
                }

                .column-two {
                    display: none;
                }
            }

            &>.neos-contentcollection {
                padding: 0;
            }
        }

        &>.neos-contentcollection {
            padding: 0;
        }
    }

    // ridurre le spaziature logo - social senza sdoppiare il contenuto
    .logo-footer {
        padding-bottom: 0;
    }

    .social-footer {
        margin-top: 0;
    }

    // Per centrare la S a sinistra. L'importante è perchè il position viene dato nel tag style e quindi difficile da sovrascrivere
    .software-column {
        >.column-image {
            background-position: calc(50% + 240px) center !important;
        }
    }

    .software-column-page {
        >.column-image {
            background-position: calc(56% + 100px) center !important;
        }
    }

    .services-column-page {
        >.column-image {
            background-position: calc(100% + 100px) center !important;
        }
    }

    .flexible-column-page {
        >.column-image {
            background-position: calc(80% + 100px) center !important;
        }
    }

    .stream-column-page {
        >.column-image {
            background-position: 100% center !important;
        }
    }

    .white-boxed-container {
        padding: 1.2rem;
    }

    .plus .adam-text p span {
        position: relative;
    
        &::after {
            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 26.3.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Livello_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 65 65' style='enable-background:new 0 0 65 65;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:none;stroke:%23FFFFFF;stroke-width:2;stroke-miterlimit:10;%7D%0A%3C/style%3E%3Cpolygon class='st0' points='42.6,62.8 22.3,62.8 22.3,42.6 2,42.6 2,22.3 22.3,22.3 22.3,2 42.6,2 42.6,22.3 62.8,22.3 62.8,42.6 42.4,42.6 '/%3E%3C/svg%3E%0A");
            font-size: unset;
            top: -2px;
            right: -35px;
            height: 30px;
            width: 30px;
        }
    }

    .big-number-sustainability {
        .salvagnini-white,
        .salvagnini-red {
            font-size: 120px;
        }
    }

    .negative-magin-left {
        margin-left: -20px;
    
        span,
        h3 {
            font-size: 30px;
        }
    }
}

@media screen and (min-width: $medium) and (max-width: $large) {
    // Questa dovrebbe togliere lo stretch alle immagine dell'iphone, prova a tuttte le foto per tagliare la testa al problema
    .ops-column {
        >.column-image {
            background-position-x: 30% !important;
        }
    }
}

@media screen and (max-width: $large) {
    .custom-container {
        .two-columns {
            .column-one,
            .column-two {
                width: 50% !important;
            }
        }
    }
        
    .custom-small-container {
        .two-columns {
            .column-one,
            .column-two {
                width: 50% !important;
            }
        }
    }
    
    .salvagnini-installation-info-img {
        padding-left: 40px;
    }

    .img-homepage {
        .adam__text-wrapper {
            margin-left: 30px;
        }
    }

    .salvagnini-negative-margin-top.columns {
        margin-top: -100px;
    }

    .column-center.sustainability {
        max-width: 90%;
    }
}

@media screen and (min-width: $large) {
    .min-height-image {
        min-height: 590px;
    }

    .two-columns {
        &.boxLeft {
            .column-one {
                padding-right: 7rem;

                .indented-text {
                    max-width: 400px;
                    margin-right: 0;
                }
            }

            .column-two {
                padding-left: 2rem;
            }
        }

        &.boxRight {
            .column-one {
                padding-right: 2rem;
            }

            .column-two {
                padding-left: 7rem;

                .indented-text {
                    max-width: 400px;
                    margin-right: 0;
                }
            }
        }
    }
}

@media screen and (min-width: $medium) {
    .two-columns {
        &.boxLeft {
            .column-one {
                padding-right: 4rem;
            }

            .column-two {
                padding-left: 2rem;
            }
        }

        &.boxRight {
            .column-one {
                padding-right: 2rem;
            }

            .column-two {
                padding-left: 4rem;
            }
        }
    }
}

// ******* SPECIAL BREAKPOINTS *******
@media screen and (max-width: 660px) {
    .salvagnini-installation-info {
        .adam-text-and-image {
            margin-left: 0 !important;
            flex-direction: column !important;
            text-align: center !important;
        }
    }
}

@media screen and (min-width: 660px) and (max-width: $small) {
    .salvagnini-installation-info {
        .adam-text-and-image__text {
            margin-left: 2rem !important;
        }
    }
}

@media screen and (max-width: 767px) {
    // Sempre per centrare la S della sezione software, usare $small creava un problema a versione tablet
    .software-column {
        &>.column-image {
            padding-top: 300px !important;
            background-color: black !important;
            background-repeat: no-repeat !important;
            background-size: auto !important;
            background-position: 32% top !important;

            .two-columns {
                text-align: center;
            }
        }
    }

    .software-column-page {
        &>.column-image {
            padding-top: 300px !important;
            background-color: black !important;
            background-repeat: no-repeat !important;
            background-size: auto !important;
            background-position: 24% 100% !important;

            .two-columns {
                text-align: center;
            }
        }
    }

    .services-column-page {
        &>.column-image {
            position: relative;

            &:before {
                content: "";
                background: linear-gradient(180deg, rgba(34, 193, 195, 0) 0%, rgba(0, 0, 0, 0.40) 100%);
                width: 100%;
                height: 100%;
                position: absolute;
                z-index: 10;
            }

            * {
                position: relative;
                z-index: 12;
            }

            &>.neos-contentcollection {
                section {
                    &:nth-child(2) {
                        padding-bottom: 0;
                    }
                }

                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
        }
    }
}

@media screen and (max-width: 769px) {
    .three-columns-tablet {
        >.three-columns {
            .three-columns-column {
                flex-basis: 100%;
                margin-bottom: 1rem;
            }
        }
    }
}
