// Primitive overrides
$custom-margins: 0.5rem !default;
$custom-padding: 1rem !default;
$custom-input-background: #fff !default;
$margins: $custom-margins;
$padding: $custom-padding;
$input-background: $custom-input-background;

$mobile: $small;
$tablet: $medium;
$desktop: $large;

$column-padding: $padding !default;
$margin-bottom: $padding !default;
$container-padding: $padding * 2 !default;

$light-font-color: lighten($font-color, 10%) !default;
$disabled-font-color: lighten($font-color, 40%) !default;

$background-colors: (
	"primary": $primary-color,
	"secondary": $secondary-color,
	"alternate": $alternate-background,
) !default;

$spacing-scale-unit: $padding !default;
$mobile-spacing-scale-unit: 0.75rem !default;
$spacing-scale: (
	"1": 1,
	"2": 2,
	"4": 4,
) !default;

$has-background: "[class*=background-]" !default;
$background-padding: $padding * 2 !default;
